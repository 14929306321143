import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTheme, useMediaQuery } from '@mui/material';

import cookieIcon from './icons/Vector (1).svg'
import backIcon from './icons/ep_back.svg'



const options = [
    'None',
    'Atria',
    'Callisto',
    'Dione',
    'Ganymede',
    'Hangouts Call',
    'Luna',
    'Oberon',
    'Phobos',
    'Pyxis',
    'Sedna',
    'Titania',
    'Triton',
    'Umbriel',
];

function ConfirmationDialogRaw(props) {
    const { onClose, value: valueProp, open, ...other } = props;
    const [value, setValue] = React.useState(valueProp);
    const radioGroupRef = React.useRef(null);

    React.useEffect(() => {
        if (!open) {
            setValue(valueProp);
        }
    }, [valueProp, open]);

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose(value);
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const cookiesContent = "We use cookies to ensure you get the best experience on our platform. Cookies allow us to remember your preferences, ensure secure login, and analyze traffic to improve our services. For technical purposes, to enhance and analyze site usage and to support our marketing efforts. By clicking 'Accept', you agree to our use of cookies for these purposes and to store cookies on your device. If you choose not to accept cookies, we will only use essential cookies required for the operation of the site. Please note that by not accepting cookies, you may not have access to all the features and functionalities of the service. Do you accept the use of cookies?"

    const cookiesContentBeforeExpand = 'We use cookies to ensure you get the best experience on our platform. Cookies allow us to remember your preferences, ensure secure login, and analyze traffic to improve our services. For technical purposes, to enhance and analyze site usage and to support our marketing efforts.  ';

    const cookiesContentAfterExpand = "By clicking 'Accept', you agree to our use of cookies for these purposes and to store cookies on your device. If you choose not to accept cookies, we will only use essential cookies required for the operation of the site. Please note that by not accepting cookies, you may not have access to all the features and functionalities of the service."

    const cookiesQuestion = 'Do you accept the use of cookies?  '

    const DeclineText = 'You have opted out of non-essential cookies. We respect your privacy and will only use the necessary cookies required to maintain the functionality of our platform. Please be aware that this might limit your user experience, and some features may not be available without accepting cookies.'

    const [ExpandedText, setExpandedText] = React.useState(false);

    const handleExpandText = () => {
        setExpandedText(true)
    }
    const HandleShrinkText = () => {
        setExpandedText(false)
    }

    const [declineCookies, setDeclineCookies] = React.useState(false);
    const handelDeclineCookies = () => {
        setDeclineCookies(true)
    }
    const backToMainContent = () => {
        setDeclineCookies(false)
    }

    const handleAcceptCookies = () => {
        localStorage.setItem('cookiesState', 'accept');
    }

    const handleDeclineCookies = () => {
        localStorage.setItem('cookiesState', 'decline');
    }

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


    return (
        <Dialog
            sx={{
                '& .MuiDialog-paper': {
                    width: '80%',
                    maxHeight: 435,
                    maxWidth: 650,
                    borderRadius:'20px',
                    // padding:'7px',
                    position: 'fixed',
                    left: isSmallScreen ? '' : 50,
                    bottom: isSmallScreen ? '0' : 10,

                },
            }}

            maxWidth="xs"
            TransitionProps={{ onEntering: handleEntering }}
            open={open}
            {...other}
        >
            <DialogContent >
                {declineCookies ?
                    <div className='d-flex align-items-center cursor-pointer mb-3'>
                        <img src={backIcon} alt="" className='me-2' />
                        <h5 className='mb-1 headerResponsive' onClick={backToMainContent}>Back</h5>
                    </div>
                    :
                    <div className='d-flex align-items-center mb-3'>
                        <img src={cookieIcon} alt="" className='me-3' />
                        <h4 className='m-0 headerResponsive'>Welcome to grandtransfer.io , we use cookies!</h4>
                    </div>
                }

                <div>
                    {declineCookies ?
                        <p className='m-0 cookiesContent'>{DeclineText}
                            <p className='m-0'> If you wish to change your decision later, you can do so from the cookie settings on our website.</p>
                        </p> :
                        <>
                            <p className='m-0 cookiesContent'>{cookiesContentBeforeExpand}<span className={`textPrimary fw-semibold text-decoration-underline cursor-pointer ${ExpandedText && 'd-none'}`} onClick={handleExpandText}>
                                Read more
                            </span></p>

                            {ExpandedText && <div className='cookiesContent'>
                                <p className='m-0'>{cookiesContentAfterExpand}</p>
                                <div>{cookiesQuestion}<span className='textPrimary fw-semibold text-decoration-underline cursor-pointer' onClick={HandleShrinkText}>Less view</span></div>
                            </div>}
                        </>
                    }


                </div>
            </DialogContent>
            <DialogActions sx={{ padding: '10px 20px 20px 20px !important' }}>
                {declineCookies ?
                    <Button onClick={() => { handleDeclineCookies(); handleCancel() }} className='cookiesAcceptBTN' sx={{ width: '100%', padding: '8px' }}>
                        Continue with Necessary Cookies
                    </Button>
                    :
                    <>
                        <Button onClick={() => { handleAcceptCookies(); handleCancel() }} className='cookiesAcceptBTN' sx={{ width: '50%', padding: '8px' }}>
                            Accept Cookies
                        </Button>
                        <Button onClick={() => { handelDeclineCookies() }} sx={{ width: '50%', padding: '8px' }} className='cookiesDeclineBTN'>Decline Cookies</Button>
                    </>
                }

            </DialogActions>
        </Dialog>
    );
}

ConfirmationDialogRaw.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
};

export default function Cookies() {
    const [open, setOpen] = React.useState(true);
    const [value, setValue] = React.useState('Dione');

    const handleClickListItem = () => {
        setOpen(true);
    };

    const handleClose = (newValue) => {
        setOpen(false);

        if (newValue) {
            setValue(newValue);
        }
    };

    return (
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <List component="div" role="group">
                <ListItemButton divider disabled>
                    <ListItemText primary="Interruptions" />
                </ListItemButton>
                <ListItemButton
                    divider
                    aria-haspopup="true"
                    aria-controls="ringtone-menu"
                    aria-label="phone ringtone"
                    onClick={handleClickListItem}
                >
                    <ListItemText primary="Phone ringtone" secondary={value} />
                </ListItemButton>
                <ListItemButton divider disabled>
                    <ListItemText primary="Default notification ringtone" secondary="Tethys" />
                </ListItemButton>
                <ConfirmationDialogRaw
                    id="ringtone-menu"
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    value={value}
                />
            </List>
        </Box>
    );
}
