


import React from 'react'

function SupportSection({ topPadding }) {
    return (

        <section className={topPadding == false ? " text-start pt-0" : " text-start pt1"}>
            <div className="relative sm:py-[12vw] sm:mt-0 mt-[10vw] py-[10vw] lg:pt-[4.27083333333vw] lg:pb-[8.02083333333vw] lg:px-[9.79166666667vw] sm:px-[6%] px-[5%] flex justify-end overflow-hidden">
                <img
                    src="./assets/images/home/footerbg.png"
                    alt="wavy bg"
                    className="absolute top-0 left-0 w-full lg:h-[41.1458333333vw] h-full flipped"
                />
                <div className="lg:w-[34.896vw] heroBox border12 lg:py-[3.125vw] sm:py-[6vw] py-[10vw] relative border12 bg-[#FFF]">
                    <div className="mx-[auto] sm:w-[90%] lg:w-[81%] w-[81%] flex flex-col items-start">
                        <div className="text-[#18181B]">
                            <h3 data-aos="fade-up" className="text50">
                                We are here to assist you in any way we can
                            </h3>
                            <p
                                data-aos="fade-down"
                                className="text22 lg:mt-[1.04166666667vw] mt1"
                            >
                                You can reach out to our dedicated team. We're eager to provide
                                you with the assistance you need.
                            </p>
                        </div>
                        <a
                            target="_blank"
                            href="https://support.grandautomation.io/login"
                            className="lg:mt-[3.125vw] sm:mt-[5vw] mt-[8vw] SupporttBtn SupportArrowHover bg-[#992861]"
                        >
                            <span className='me-2'> Support </span>
                            <img
                                src="./assets/images/icons/arrow.svg"
                                alt="arrow"
                                className="SupportArrowStyle lg:w-[0.83333333333vw] sm:w-[2.30208333333vw] w-[3.05128205128vw] flipped"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SupportSection
