import React from 'react';
import './Loader.css'; // Import loader styles

function NavigateLoader({ text }) {
    return (
        <div className="loader-container">
            <div className="loader-content">
                <div className="loader-spinner"></div>
                {text && <p className="loader-text">{text}</p>}
            </div>
        </div>
    );
}

export default NavigateLoader;
