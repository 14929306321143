
import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MdOutlineErrorOutline } from "react-icons/md";
import PasswordChecklist from "react-password-checklist"
import valid from '../../../../images/check (4).png';
import invalid from '../../../../images/close (3).png';
import Checkbox from '@mui/material/Checkbox';
import { Link, json, useNavigate } from 'react-router-dom';
import './style.css';
// import authService from '../../Services/authService';
import { toast, Toaster, ToastBar } from 'react-hot-toast';
// import ButtonLoader from '../../Components/Common/ButtonLoader';
import GrandAutomationLogo from '../../../../images/ProductsLogo/DarkBg/Frame.svg'
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md'; // Import eye icons
import axios from 'axios';
import authService from '../../../../Services/authService';
import { userIsLoginContext } from '../../../../Context/UserIsLoginProvider';
import { ga } from '../../../../config';

function RegisterPage({ setActive, setToken }) {
  const { authData, setAuthData } = useContext(userIsLoginContext);


  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const initialValuesInput = {
    FullName: '',
    email: '',
    password: '',
    ComfirmPassword: '',
    CompanyName: "",
    Address: "",
    TAX_ID: ""
  };

  const nav = useNavigate();

  const formik = useFormik({
    initialValues: initialValuesInput,
    validationSchema: Yup.object().shape({
      FullName: Yup.string().required('Please Enter Your Full Name'),
      email: Yup.string().email('Invalid email').required('Please Enter Your Email Address'),
      password: Yup.string().required('Please Enter Your Password'),
      ComfirmPassword: Yup.string().required('Please Repeat Your Password')
        .oneOf([Yup.ref('password')], 'Passwords must match'),
      CompanyName: checked ? Yup.string().required("Please Enter Company's Name") : Yup.string(),
      Address: checked ? Yup.string().required("Please Enter company's Address") : Yup.string(),
      TAX_ID: checked ? Yup.string().required("Please Enter Company's TAX ID") : Yup.string(),
    }),
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        setLoading(true)
        const sendData = {
          name: values.FullName,
          email: values.email,
          password: values.password,
          confirm_password: values.ComfirmPassword,
          is_company: checked,
          company_name: values.CompanyName,
          company_address: values.Address,
          company_tax_number: values.TAX_ID

        }

        // Call the register method from the authService
        const response = await authService.register(sendData);

        if (response.success) {
          toast.success('Registration Successful');
          setLoading(false)
          // localStorage.setItem('auth', JSON.stringify(response.data));
          setToken(response.data.token)
          // setAuthData(response.data)
          setActive('Otp')

        } else {
          toast.error(response.message)
          setLoading(false)
        }


        // Reset the form after successful submission
        resetForm();
      } catch (error) {
        setLoading(false)
        console.error('Error:', error);
      } finally {
        // Set submitting state to false after submission
        setSubmitting(false);
      }
    },
  });



  // const checkALLRequiredHasFill = () => {
  //   if (formik.values.FullName !== '' && formik.values.email !== '' && formik.values.password !== '' && formik.values.ComfirmPassword !== '') {
  //     if (checked) {
  //       if (formik.values.CompanyName !== '' && formik.values.Address !== '' && formik.values.TAX_ID !== '') {
  //         return false
  //       } else {
  //         return true
  //       }
  //     } else {
  //       return false
  //     }
  //   } else {
  //     return true
  //   }
  // }

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);



  return (

    <div className='flex items-center justify-center flex-1 text-white px-md-0 registerOverFlow'>
      <div className='p-3 registerWidth'>
        <div className='flex flex-col-reverse mb-10 px-1'>
          <div className=''>
            <h6 style={{ fontSize: "1.5rem", fontWeight: "600" }} className='md:text-xl text-base font-semibold'>SIGNUP</h6>
            <p className='font-light'>One account for all our products</p>
          </div>
          <div className='flex justify-center md:justify-start d-sm-flex d-none'>
            <img src={GrandAutomationLogo} alt="" style={{ width: "10rem", marginBottom: "4rem" }} className='md:w-40 w-28 sm:mb-0 mb-10' />
          </div>
        </div>

        {/* Register Form */}
        <form onSubmit={formik.handleSubmit} className='overflow-auto px-1'>

          <div className='custom-grid md'>
            {/* Full Name */}
            <div>
              <div className='flex'>
                <label htmlFor="FullName" style={{ fontSize: "1rem", fontWeight: "500" }} className="block text-sm font-medium leading-6 me-1">
                  Full Name
                </label>
                <span style={{ color: "#ff0000" }} className='registerError'>*</span>
              </div>
              <div style={{ position: "relative" }} className="mt-2 relative">
                <input
                  type="text"
                  name="FullName"
                  id="FullName"
                  className={formik.errors.FullName && formik.touched.FullName ? 'registerInputsInValid' : 'registerInputsValid'}
                  placeholder='Enter Your Name Here'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.FullName && formik.touched.FullName && <MdOutlineErrorOutline style={{ color: "#ff0000", fontSize: "1.25rem", position: "absolute", right: "0.625rem", top: "0.625rem" }} />}
              </div>

              {formik.errors.FullName && formik.touched.FullName ?

                <div className="mt-2 registerError text-sm">
                  {formik.errors.FullName}
                </div> : null}
            </div>

            {/*Email*/}
            <div>
              <div style={{ width: "100%" }}>
                <div className='flex'>
                  <label htmlFor="email" style={{ fontSize: "1rem", fontWeight: "500" }} className="block text-sm font-medium leading-6 me-1">
                    Email Address
                  </label>
                  <span style={{ color: "#ff0000" }} className='registerError'>*</span>
                </div>
                <div style={{ position: "relative" }} className="mt-2 relative">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className={formik.errors.email && formik.touched.email ? 'registerInputsInValid' :
                      'registerInputsValid'}
                    placeholder="Enter Your Email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.email && formik.touched.email && <MdOutlineErrorOutline style={{ color: "#ff0000", fontSize: "1.25rem", position: "absolute", right: "0.625rem", top: "0.625rem" }} />}
                </div>

                {formik.errors.email && formik.touched.email ?

                  <div className="mt-2 registerError text-sm">
                    {formik.errors.email}
                  </div> : null}
              </div>
            </div>
          </div>

          <div className='custom-grid2 md2 gap-4 mt-4'>
            <div>
              {/* Password */}
              <div>
                <div className='flex'>
                  <label htmlFor="password" style={{ fontSize: "1rem", fontWeight: "500" }} className="block text-sm font-medium leading-6 me-1">
                    Password
                  </label>
                  <span style={{ color: "#ff0000" }} className='registerError'>*</span>
                </div>

                <div style={{ position: "relative" }} className="my-2 relative">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="current-password"
                    className={formik.errors.password && formik.touched.password ? 'registerInputsInValid' :
                      'registerInputsValid'}
                    placeholder='Enter Password Here'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.password && formik.touched.password && <MdOutlineErrorOutline style={{ color: "#ff0000", fontSize: "1.25rem", position: "absolute", right: "0.625rem", top: "0.625rem" }} />}
                  {showPassword ? (
                    <MdOutlineVisibilityOff style={{ color: "#4c4c4c", fontSize: "1.25rem", position: "absolute", right: "0.625rem", top: "0.625rem", cursor: "pointer" }} onClick={() => setShowPassword(false)} />
                  ) : (
                    <MdOutlineVisibility style={{ color: "#4c4c4c", fontSize: "1.25rem", position: "absolute", right: "0.625rem", top: "0.625rem", cursor: "pointer" }} onClick={() => setShowPassword(true)} />
                  )}
                </div>


                {formik.errors.password && formik.touched.password ?

                  <div className="mt-2 registerError text-sm">
                    {formik.errors.password}
                  </div> :

                  null}
              </div>

              {/*Comfirm Password */}
              <div style={{ marginTop: "1.5rem" }}>
                <div className='flex'>
                  <label htmlFor="ComfirmPassword" style={{ fontSize: "1rem", fontWeight: "500" }} className="block text-sm font-medium leading-6 me-1">
                    Repeat password
                  </label>
                  <span style={{ color: "#ff0000" }} className='registerError'>*</span>
                </div>

                <div style={{ position: "relative" }} className="my-2 relative">
                  <input
                    id="ComfirmPassword"
                    name="ComfirmPassword"
                    type={showConfirmPassword ? 'text' : 'password'}
                    autoComplete="current-password"
                    className={formik.errors.ComfirmPassword && formik.touched.ComfirmPassword ? 'registerInputsInValid' :
                      'registerInputsValid'}
                    placeholder='Repeat Your Password Here'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.ComfirmPassword && formik.touched.ComfirmPassword && <MdOutlineErrorOutline style={{ color: "#ff0000", fontSize: "1.25rem", position: "absolute", right: "0.625rem", top: "0.625rem" }} />}
                  {showConfirmPassword ? (
                    <MdOutlineVisibilityOff style={{ color: "#4c4c4c", fontSize: "1.25rem", position: "absolute", right: "0.625rem", top: "0.625rem", cursor: "pointer" }} onClick={() => setShowConfirmPassword(false)} />
                  ) : (
                    <MdOutlineVisibility style={{ color: "#4c4c4c", fontSize: "1.25rem", position: "absolute", right: "0.625rem", top: "0.625rem", cursor: "pointer" }} onClick={() => setShowConfirmPassword(true)} />
                  )}
                </div>

                {formik.errors.ComfirmPassword && formik.touched.ComfirmPassword ?

                  <div className="mt-2 registerError text-sm">
                    {formik.errors.ComfirmPassword}
                  </div> :

                  null}
              </div>

            </div>


            <div style={{ marginTop: "1.5rem" }} className=' text-sm text-start md:items-center items-start md:pt-10 pt-0 flex flex-col justify-center mb-6'>
              <PasswordChecklist
                rules={["minLength", "specialChar", "number", "capital", "match"]}
                minLength={5}
                value={formik.values.password}
                valueAgain={formik.values.ComfirmPassword}
                onChange={(isValid) => { }}
                iconComponents={{ ValidIcon: <img src={valid} width={10} style={{ marginRight: "0.75rem", marginTop: "0.5rem" }} />, InvalidIcon: <img src={invalid} width={10} style={{ marginRight: "0.75rem", marginTop: "0.5rem" }} /> }}
              />
            </div>

          </div>


          <hr style={{ borderColor: "#252526", marginTop: "1.125rem" }} className='border-[#252526] my-3' />

          {/* checkbox */}
          <div style={{ display: "flex", alignItems: "center" }} className='flex items-center'>
            <Checkbox
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
              style={{ color: '#9fa6b2' }}
              className='ms-0'
            />
            <label htmlFor="comments" style={{ color: "white", marginLeft: "0.25rem", fontSize: "0.875rem", textDecoration: "underline" }} className="text-white ms-1 text-sm underline">
              Registering as a company
            </label>
          </div>

          {/* company Register */}

          <div className='row w-100'>
            {/* Company Name */}
            <div className='col-6'>
              <label htmlFor="CompanyName" style={{ color: checked ? "white" : "#484848", fontSize: "1rem", fontWeight: "500" }} className={checked ? "text-white block text-sm font-medium leading-6 me-1" : "text-[#484848] block text-sm font-medium leading-6 me-1"}>
                Company Name
              </label>

              <div style={{ position: "relative" }} className="mt-2 relative">
                <input
                  type="text"
                  name="CompanyName"
                  id="CompanyName"
                  className={formik.errors.CompanyName && formik.touched.CompanyName && checked ? 'registerInputsInValid' :
                    'registerInputsValid'}
                  placeholder='Company Name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={checked ? false : true}
                />
                {formik.errors.CompanyName && formik.touched.CompanyName && checked && <MdOutlineErrorOutline style={{ color: "#ff0000", fontSize: "1.25rem", position: "absolute", right: "0.625rem", top: "0.625rem" }} />}
              </div>

              {formik.errors.CompanyName && formik.touched.CompanyName && checked ?

                <div className="mt-2 registerError text-sm">
                  {formik.errors.CompanyName}
                </div> : null}
            </div>

            {/* Address */}
            <div className='col-6'>
              <label htmlFor="Address" style={{ color: checked ? "white" : "#484848", fontSize: "1rem", fontWeight: "500" }} className={checked ? "text-white block text-sm font-medium leading-6 me-1" : "text-[#484848] block text-sm font-medium leading-6 me-1"}>
                Address
              </label>

              <div style={{ position: "relative" }} className="mt-2 relative">
                <input
                  type="text"
                  name="Address"
                  id="Address"
                  className={formik.errors.Address && formik.touched.Address && checked ? 'registerInputsInValid' :
                    'registerInputsValid'}
                  placeholder='Address'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={checked ? false : true}
                />
                {formik.errors.Address && formik.touched.Address && checked && <MdOutlineErrorOutline style={{ color: "#ff0000", fontSize: "1.25rem", position: "absolute", right: "0.625rem", top: "0.625rem" }} />}
              </div>

              {formik.errors.Address && formik.touched.Address && checked ?

                <div className="mt-2 registerError text-sm">
                  {formik.errors.Address}
                </div> : null}
            </div>

            <div className='col-12 w-100 mt-3'>
              <label htmlFor="Country" style={{ color: checked ? "white" : "#484848", fontSize: "1rem", fontWeight: "500" }} className={checked ? "text-white block text-sm font-medium leading-6 me-1" : "text-[#484848] block text-sm font-medium leading-6 me-1 co;-12"}>
                TAX ID
              </label>

              <div style={{ position: "relative" }} className="mt-2 relative">
                <input
                  type="number"
                  name="TAX_ID"
                  id="TAX_ID"
                  className={formik.errors.TAX_ID && formik.touched.TAX_ID && checked ? 'registerInputsInValid' :
                    'registerInputsValid'}
                  placeholder='TAX_ID'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={checked ? false : true}
                />
                {formik.errors.TAX_ID && formik.touched.TAX_ID && checked && <MdOutlineErrorOutline style={{ color: "#ff0000", fontSize: "1.25rem", position: "absolute", right: "0.625rem", top: "0.625rem" }} />}
              </div>

              {formik.errors.TAX_ID && formik.touched.TAX_ID && checked ?

                <div className="mt-2 registerError text-sm">
                  {formik.errors.TAX_ID}
                </div> : null}
            </div>

            <div style={{
              color: '#4c4c4c',
              fontSize: '16px', // assuming 'md:text-base' maps to 16px
              fontWeight: '600', // 'font-semibold' typically maps to 600
              marginTop: '16px' // 'mt-4' typically maps to 16px in Tailwind
            }}>
              By registering you agree to the <span style={{
                fontWeight: '200', // 'font-extralight' typically maps to 200
                textDecoration: 'underline',
                color: 'white',
                marginLeft: '4px', // 'ms-1' typically maps to 4px in Tailwind
                cursor: 'pointer' // 'pointer' typically maps to 'cursor-pointer' in Tailwind
              }}
              
              onClick={() => 
              {
                // open the terms and conditions page in a new tab
                window.open(ga.GA_APP+'/terms-and-conditions')
              }}
              >Term and Conditions</span>
            </div>

          </div>

          <button style={{ backgroundColor: "#4c4c4c", width: '100%', fontSize: 16, marginTop: 2 }} type="submit" className='py-1 px-8 text-white font-semibold text-lg rounded-md mt-4'>
            {loading ? 'Please Wait ...' : 'Sign Up'}
          </button>
        </form>

      </div>
    </div>

  )
}


export default RegisterPage
