import React, { useState, useEffect, useContext, useRef } from "react";
import { toggleSidebar } from "../LandingPage/toggle.js";
import MenuIcon from "../../Icons/menuIcon.svg";
import logo from "../../images/Logos02.png";
import logoSidebar from "../../images/Logo01.png";
import { Link, useNavigate } from "react-router-dom";
import ProfileIcon from "../../Icons/logout icon.svg";
import SidePanelIcon from "../../Icons/icon-park-outline_application-menu.svg";
import SupportIcon from "../../Icons/radix-icons_external-link.svg";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

// Import products images
import TransferProduct from "../../images/ProductsLogo/whiteBG/TransferWhiteBg.svg";
import Calender from "../../images/ProductsLogo/whiteBG/calenderWhiteBg.svg";
import Form from "../../images/ProductsLogo/whiteBG/Property 1=G-Form.svg";
import WorkFlow from "../../images/ProductsLogo/whiteBG/Property 1=G-Workflow.svg";
import SupportTick from "../../images/ProductsLogo/whiteBG/Property 1=G-Support Tick.svg";
import HRM from "../../images/ProductsLogo/whiteBG/Property 1=G-HRM.svg";
import Signature from "../../images/ProductsLogo/whiteBG/Property 1=G-Signature.svg";
import Accounting from "../../images/ProductsLogo/whiteBG/Property 1=G-Accounting.svg";
import Projects from "../../images/ProductsLogo/whiteBG/Property 1=G-Projects.svg";
import Forms from "../../images/ProductsLogo/whiteBG/Property 1=G-Forms.svg";
import Inventory from "../../images/ProductsLogo/whiteBG/Property 1=G-Inventory.svg";
import VideoConference from "../../images/ProductsLogo/whiteBG/Property 1=G-Video Conference.svg";

// import icons
import settingsIcon from "../../Icons/settings icon.svg";
import logoutIcon from "../../Icons/logout icon.svg";

import AOS from "aos";
import { createPopper } from "@popperjs/core";
import "aos/dist/aos.css";
import HomePageButton from "../../Components/HomePageButton.js";
import TabContent from "./Tabs/TabContent.jsx";
import SupportSection from "../../Components/SupportSection/SupportSection.jsx";
import Footer from "../../Components/Footer/Footer.jsx";
import { Helmet } from "react-helmet";
import axios from "../../Services/axiosConfig.js";

import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";

function Pricing({ open2, toggle }) {
  // products logos
  const products = [
    TransferProduct,
    Calender,
    Form,
    WorkFlow,
    SupportTick,
    HRM,
    Signature,
    Accounting,
    Projects,
    Forms,
    Inventory,
    VideoConference,
  ];

  // offcanvas
  const [open, setOpen] = useState(false);

  // dropDown Menu
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDropdown = Boolean(anchorEl);
  const id = openDropdown ? "simple-popover" : undefined;

  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease",
      once: "true", // whether animation should happen only once - while scrolling down
      mirror: true,
    });
  }, []);

  const [comparePlansToggle, setComparePlanToggle] = useState(false);
  const [PlanState, setPlanState] = useState("monthly");
  const [PlansArrowClicked, setPlansArrowClicked] = useState(false);

  const tableRef = useRef(null);
  const navBarHeight = 64; // Height of your navbar, adjust as needed

  useEffect(() => {
    if (PlansArrowClicked && tableRef.current) {
      const divTopPosition = tableRef.current.offsetTop - navBarHeight;
      window.scrollTo({ top: divTopPosition, behavior: "smooth" });
      // Reset the PlansArrowClicked state after scrolling
      // setPlansArrowClicked(false);
    }
  }, [PlansArrowClicked]);

  useEffect(() => {
    if (window.location.hash === "#plans") {
      document.getElementById("plans").scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  // getting data

  const [Data, setData] = useState([]);

  const getData = () => {
    axios
      .get("https://api.grandtransfer.io/api/landing-plans")
      .then((res) => {
        // console.log(res.data)
        setData(res.data);
      })
      .catch((error) => {
        // console.log(error)
      })
  }

  useEffect(() => {
    getData();
  }, []);

  // getting comparison data

  const getComparisonData = (index, prop) => {
    if (Data) {
      if (Data[index][prop] === "-1") {
        return "Unlimited";
      } else {
        return Data[index][prop];
      }
    }
  };

  return (
    <div>
      <Helmet>
        <title>
          Efficient File Sharing Service Pricing - Affordable Pricing Plans |
          GrandTransfer
        </title>
        <meta
          name="title"
          content="Efficient File Sharing Service Pricing - Affordable Pricing Plans | GrandTransfer"
        />
        <meta
          name="keywords"
          content="file management optimization, secure file sharing platform, data protection services, affordable file sharing solutions, efficient file transfer pricing, secure data management, cost-effective file storage, encryption service pricing"
        />

        <meta
          name="description"
          content="Experience optimized file management with GrandTransfer's secure and efficient file sharing platform. Benefit from our affordable pricing plans tailored to ensure ironclad data protection for businesses of all sizes. Explore our packages for secure, efficient, and cost-effective file management solutions."
        />
        <meta property="og:url" content="https://grandtransfer.io/pricing" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Efficient File Sharing Service Pricing - Affordable Pricing Plans | GrandTransfer"
        />
        <meta
          property="og:description"
          content="Experience optimized file management with GrandTransfer's secure and efficient file sharing platform. Benefit from our affordable pricing plans tailored to ensure ironclad data protection for businesses of all sizes. Explore our packages for secure, efficient, and cost-effective file management solutions."
        />
        <meta
          property="og:image"
          content="https://www.grandtransfer.io/assets/images/ogImage.jpg"
        />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta
          property="og:image:alt"
          content="Experience optimized file management with GrandTransfer's secure and efficient file sharing platform. Benefit from our affordable pricing plans tailored to ensure ironclad data protection for businesses of all sizes. Explore our packages for secure, efficient, and cost-effective file management solutions."
        />
        <meta
          name="google-site-verification"
          content="mHLXZqgcUz-ADXgzZ39KarFwbd3KiR1HFyICTESENFs"
        />
        <meta
          property="twitter:title"
          content="Efficient File Sharing Service Pricing - Affordable Pricing Plans | GrandTransfer"
        />
        <meta
          property="twitter:description"
          content="Experience optimized file management with GrandTransfer's secure and efficient file sharing platform. Benefit from our affordable pricing plans tailored to ensure ironclad data protection for businesses of all sizes. Explore our packages for secure, efficient, and cost-effective file management solutions."
        />
        <meta
          property="twitter:image"
          content="https://www.grandtransfer.io/assets/images/ogImage.jpg"
        />
        <meta property="twitter:image:width" content="1200" />
        <meta property="twitter:image:height" content="630" />
        <meta
          property="twitter:image:alt"
          content="Experience optimized file management with GrandTransfer's secure and efficient file sharing platform. Benefit from our affordable pricing plans tailored to ensure ironclad data protection for businesses of all sizes. Explore our packages for secure, efficient, and cost-effective file management solutions."
        />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/assets/images/favicons/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/assets/images/favicons/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/assets/images/favicons/favicon-16x16.png"
        />
        <link rel="manifest" href="/assets/images/favicons/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/assets/images/favicons/safari-pinned-tab.svg"
          color="#992861"
        />
        <link rel="shortcut icon" href="/assets/images/favicons/favicon.ico" />
        <meta name="msapplication-TileColor" content="#992861" />
        <meta
          name="msapplication-config"
          content="/assets/images/favicons/browserconfig.xml"
        />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <main className="pricing-page">
        <section className="bg-black relative">
          <div className="container1720">
            <div className="z-[23] relative text-[#FFF] lg:w-[41.5625vw] pt-[30vw] pb-[12vw] sm:pt-[24vw] sm:pb-[10vw] lg:pb-[6.09375vw] lg:pt-[12.2395833333vw]">
              <h1 className="text60" data-aos="fade-up">
                Pricing
              </h1>
              <p
                className="text22 lg:mt-[1.04166666667vw] mt1"
                data-aos="fade-down"
              >
                Explore various subscription plans offered by GrandTransfer,
                tailored to meet diverse file sharing and storage needs. From
                individual users to large enterprises, our plans offer scalable
                solutions with enhanced security features.
              </p>
            </div>
            <div className="hidden lg:block absolute z-[1] right-0 bottom-0">
              <img
                className="pricingPhoto"
                src="/assets/images/home/pricing.png"
                alt="Pricing"
              />
            </div>
          </div>
        </section>
        <section id="plans">
          <div className="lg:pt-[6.51041666667vw] pt1 lg:w-[89.6875vw] mx-[auto] plansSection ">
            <div className="flex flex-col items-center">
              <div
                data-aos="fade"
                data-aos-delay={400}
                className="overflow-hidden bg-[#F4F5F7] text25 rounded-full lg:rounded-[5.05208333333vw] mx-[auto]"
              >
                <button
                  data-tab-target="#monthly"
                  className={
                    PlanState == "monthly"
                      ? " lg:px-[3.125vw] sm:px-[3.90625vw] px-[5.12820512821vw] rounded-full lg:rounded-[5.05208333333vw] tabColor text-white"
                      : " lg:px-[3.125vw] sm:px-[3.90625vw] px-[5.12820512821vw] text-[#161E2E] rounded-full lg:rounded-[5.05208333333vw]"
                  }
                  onClick={() => {
                    setPlanState("monthly");
                  }}
                >
                  <span> Monthly </span>
                </button>
                <button
                  data-tab-target="#annually"
                  className={
                    PlanState == "monthly"
                      ? " lg:px-[3.125vw] sm:px-[3.90625vw] px-[5.12820512821vw] text-[#161E2E] rounded-full lg:rounded-[5.05208333333vw]"
                      : " lg:px-[3.125vw] sm:px-[3.90625vw] px-[5.12820512821vw] rounded-full lg:rounded-[5.05208333333vw] tabColor text-white"
                  }
                  onClick={() => {
                    setPlanState("Annually");
                  }}
                >
                  <span> Annually </span>
                </button>
              </div>
            </div>

            <TabContent
              setPlanState={setPlanState}
              PlanState={PlanState}
              toggle={toggle}
              plansData={Data}
            />
          </div>
          <div>
            <div
              className="flex justify-center cursor-pointer"
              onClick={() => {
                setComparePlanToggle(!comparePlansToggle);
              }}
            >
              <div
                className="text-[#992861] border-b-[1px] border-[#992861] font-size-24 flex items-center justify-center lg:gap-x-[0.52083333333vw] sm:gap-x-[2vw] gap-x-[3vw] lg:pt-[3.64583333333vw] pt1"
                onClick={() => {
                  setPlansArrowClicked(!PlansArrowClicked);
                }}
              >
                <span className="capitalize">plans comparison </span>
                <img
                  src="./assets/images/icons/downarrow.svg"
                  alt="downarrow"
                  className={
                    PlansArrowClicked
                      ? "planArrowStyle planArrowClicked transition-all duration-300 lg:w-[0.83333333333vw] sm:w-[1.953125vw] w-[3.84615384615vw]"
                      : "planArrowStyle transition-all duration-300 lg:w-[0.83333333333vw] sm:w-[1.953125vw] w-[3.84615384615vw]"
                  }
                />
              </div>
            </div>

            <div className="text-white srollingPadding" ref={tableRef}></div>
            {comparePlansToggle && (
              <div className="overflow-visible overflow-x-auto plan transition-all duration-300 lg:w-[75.2083333333vw] lg:mx-[auto] sm:w-[92%] w-[95%]">
                <table className="overflow-visible overflow-x-auto z-[1] relative bg-[#F4F5F7] w-full border-collapse rounded tableMarginResponsive">
                  {/* Heading Row */}
                  <tbody>
                    <tr>
                      <th className="font-size-24 py-6 lg:py-[.8vw] border-b border-[#6B72804D] text-start pl-[20px] lg:pl-[2vw] whitespace-nowrap">
                        Features
                      </th>
                      <th className="font-size-24 py-6 lg:py-[.8vw] border-b border-[#6B72804D] whitespace-nowrap">
                        Free Trial
                      </th>
                      <th className="font-size-24 py-6 lg:py-[.8vw] border-b border-[#6B72804D] whitespace-nowrap">
                        Plus
                      </th>
                      <th className="font-size-24 py-6 lg:py-[.8vw] border-b border-[#6B72804D] whitespace-nowrap">
                        Pro
                      </th>
                      <th className="font-size-24 py-6 lg:py-[.8vw] border-b border-[#6B72804D] whitespace-nowrap">
                        Max
                      </th>
                    </tr>
                    {/* Storage Row */}
                    <tr className="hover:bg-gray-100 border-b border-[#6B72804D] ">
                      <td className="feature-td font-size-16 pl-[20px] lg:pl-[2vw] py-3 relative flex items-center whitespace-nowrap">
                        Storage up to
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        {getComparisonData(0, "storage_limit")}
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        {getComparisonData(1, "storage_limit")}
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        {getComparisonData(2, "storage_limit")}
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        {getComparisonData(3, "storage_limit")}
                      </td>
                    </tr>
                    {/* Monthly Transfer Row */}
                    <tr className="hover:bg-gray-100 border-b border-[#6B72804D]">
                      <td className="feature-td font-size-16 pl-[20px] lg:pl-[2vw] py-3 relative flex items-center whitespace-nowrap">
                        Monthly transfer up to
                        <Tooltip
                          title={
                            <span style={{ fontSize: "15px" }}>
                              Limit for data transfer volume per month
                            </span>
                          }
                          placement="top"
                        >
                          <img
                            src="/assets/images/home/qmark.svg"
                            alt="question mark"
                            className="cursor-help ml-2"
                          />
                        </Tooltip>
                        {/* <div className="ml-2 flex group relative">
                          <span
                            className="cursor-help w-[20px]"
                            id="tooltip-target-transfer"
                          >
                            <img
                              src="/assets/images/home/qmark.svg"
                              alt="question mark"
                            />
                          </span>
                          <div
                            id="tooltip-transfer"
                            role="tooltip"
                            className="hidden absolute z-[1212] text-white bg-[#333] p-2 border rounded shadow-lg text-sm min-w-max whitespace-normal"
                          >
                            <div className="tooltip-content">
                              Limit for data transfer volume per month
                            </div>
                            <div id="arrow-transfer" data-popper-arrow="" />
                          </div>
                        </div> */}
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        {getComparisonData(0, "monthly_transfer_limit")}/monthly
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        {getComparisonData(1, "monthly_transfer_limit")}/monthly
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        {getComparisonData(2, "monthly_transfer_limit")}/monthly
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        {getComparisonData(3, "monthly_transfer_limit")}/monthly
                      </td>
                    </tr>
                    <tr className="hover:bg-gray-100 border-b border-[#6B72804D]">
                      <td className="feature-td font-size-16 pl-[20px] lg:pl-[2vw] py-3 relative flex items-center whitespace-nowrap">
                        File size limit
                        <Tooltip
                          title={
                            <span style={{ fontSize: "15px" }}>
                              Upload files up to 5GB each
                            </span>
                          }
                          placement="top"
                        >
                          <img
                            src="/assets/images/home/qmark.svg"
                            alt="question mark"
                            className="cursor-help ml-2"
                          />
                        </Tooltip>
                        {/* <div className="ml-2 flex group relative">
                          <span
                            className="cursor-help w-[20px]"
                            id="tooltip-target-limit"
                          >
                            <img
                              src="/assets/images/home/qmark.svg"
                              alt="question mark"
                            />
                          </span>
                          <div
                            id="tooltip-file-limit"
                            role="tooltip"
                            className="hidden absolute z-[1212] text-white bg-[#333] p-2 border rounded shadow-lg text-sm min-w-max whitespace-normal"
                          >
                            <div className="tooltip-content">
                              Upload files up to 5GB each
                            </div>
                            <div id="arrow-transfer" data-popper-arrow="" />
                          </div>
                        </div> */}
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        5 GB
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        5 GB
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        5 GB
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        5 GB
                      </td>
                    </tr>
                    <tr className="hover:bg-gray-100 border-b border-[#6B72804D]">
                      <td className="feature-td font-size-16 pl-[20px] lg:pl-[2vw] py-3 relative flex items-center whitespace-nowrap">
                        Supported file types
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        All Types
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        All Types
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        All Types
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        All Types
                      </td>
                    </tr>
                    <tr className="hover:bg-gray-100 border-b border-[#6B72804D]">
                      <td className="feature-td font-size-16 pl-[20px] lg:pl-[2vw] py-3 relative flex items-center whitespace-nowrap">
                        Watermark Templates
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        {getComparisonData(0, "watermark_limit")}
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        {getComparisonData(1, "watermark_limit")}
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        {getComparisonData(2, "watermark_limit")}
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        {getComparisonData(3, "watermark_limit")}
                      </td>
                    </tr>
                    <tr className="hover:bg-gray-100 border-b border-[#6B72804D]">
                      <td className="feature-td font-size-16 pl-[20px] lg:pl-[2vw] py-3 relative flex items-center whitespace-nowrap">
                        Workspaces
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        {getComparisonData(0, "spaces_limit")}
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        {getComparisonData(1, "spaces_limit")}
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        {getComparisonData(2, "spaces_limit")}
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        {getComparisonData(3, "spaces_limit")}
                      </td>
                    </tr>
                    <tr className="hover:bg-gray-100 border-b border-[#6B72804D]">
                      <td className="feature-td font-size-16 pl-[20px] lg:pl-[2vw] py-3 relative flex items-center whitespace-nowrap">
                        Team members
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        {getComparisonData(0, "team_members_limit")}
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        {getComparisonData(1, "team_members_limit")}
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        {getComparisonData(2, "team_members_limit")}
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        {getComparisonData(3, "team_members_limit")}
                      </td>
                    </tr>
                    <tr className="hover:bg-gray-100 border-b border-[#6B72804D]">
                      <td className="feature-td font-size-16 pl-[20px] lg:pl-[2vw] py-3 relative flex items-center whitespace-nowrap">
                        Secured sharing links
                        <Tooltip
                          title={
                            <span style={{ fontSize: "15px" }}>
                              Secured sharing links
                            </span>
                          }
                          placement="top"
                        >
                          <img
                            src="/assets/images/home/qmark.svg"
                            alt="question mark"
                            className="cursor-help ml-2"
                          />
                        </Tooltip>
                        {/* <div className="ml-2 flex group relative">
                          <span
                            className="cursor-help w-[20px]"
                            id="tooltip-target-share-link"
                          >
                            <img
                              src="/assets/images/home/qmark.svg"
                              alt="question mark"
                            />
                          </span>
                          <div
                            id="tooltip-share-link"
                            role="tooltip"
                            className="hidden absolute z-[1212] text-white bg-[#333] p-2 border rounded shadow-lg text-sm min-w-max whitespace-normal"
                          >
                            <div className="tooltip-content">
                              Secured sharing links
                            </div>
                            <div id="arrow-transfer" data-popper-arrow="" />
                          </div>
                        </div> */}
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        Unlimited
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        Unlimited
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        Unlimited
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        Unlimited
                      </td>
                    </tr>
                    <tr className="hover:bg-gray-100 border-b border-[#6B72804D]">
                      <td className="feature-td font-size-16 pl-[20px] lg:pl-[2vw] py-3 relative flex items-center whitespace-nowrap">
                        Track opens &amp; downloads
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                    </tr>
                    <tr className="hover:bg-gray-100 border-b border-[#6B72804D]">
                      <td className="feature-td font-size-16 pl-[20px] lg:pl-[2vw] py-3 relative flex items-center whitespace-nowrap">
                        Share link via email
                        <Tooltip
                          title={
                            <span style={{ fontSize: "15px" }}>
                              Send file links directly from the app
                            </span>
                          }
                          placement="top"
                        >
                          <img
                            src="/assets/images/home/qmark.svg"
                            alt="question mark"
                            className="cursor-help ml-2"
                          />
                        </Tooltip>
                        {/* <div className="ml-2 flex group relative">
                          <span
                            className="cursor-help w-[20px]"
                            id="tooltip-target-email"
                          >
                            <img
                              src="/assets/images/home/qmark.svg"
                              alt="question mark"
                            />
                          </span>
                          <div
                            id="tooltip-email"
                            role="tooltip"
                            className="hidden absolute z-[1212] text-white bg-[#333] p-2 border rounded shadow-lg text-sm min-w-max whitespace-normal"
                          >
                            <div className="tooltip-content">
                              Send file links directly from the app
                            </div>
                            <div id="arrow-transfer" data-popper-arrow="" />
                          </div>
                        </div> */}
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                    </tr>
                    <tr className="hover:bg-gray-100 border-b border-[#6B72804D]">
                      <td className="feature-td font-size-16 pl-[20px] lg:pl-[2vw] py-3 relative flex items-center whitespace-nowrap">
                        Password protection
                        <Tooltip
                          title={
                            <span style={{ fontSize: "15px" }}>
                              Protect links with a required password
                            </span>
                          }
                          placement="top"
                        >
                          <img
                            src="/assets/images/home/qmark.svg"
                            alt="question mark"
                            className="cursor-help ml-2"
                          />
                        </Tooltip>
                        {/* <div className="ml-2 flex group relative">
                          <span
                            className="cursor-help w-[20px]"
                            id="tooltip-target-password"
                          >
                            <img
                              src="/assets/images/home/qmark.svg"
                              alt="question mark"
                            />
                          </span>
                          <div
                            id="tooltip-password"
                            role="tooltip"
                            className="hidden absolute z-[1212] text-white bg-[#333] p-2 border rounded shadow-lg text-sm min-w-max whitespace-normal"
                          >
                            <div className="tooltip-content">
                              Protect links with a required password
                            </div>
                            <div id="arrow-transfer" data-popper-arrow="" />
                          </div>
                        </div> */}
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                    </tr>
                    <tr className="hover:bg-gray-100 border-b border-[#6B72804D]">
                      <td className="feature-td font-size-16 pl-[20px] lg:pl-[2vw] py-3 relative flex items-center whitespace-nowrap">
                        File link expiration
                        <Tooltip
                          title={
                            <span style={{ fontSize: "15px" }}>
                              Set expiration dates for shared links
                            </span>
                          }
                          placement="top"
                        >
                          <img
                            src="/assets/images/home/qmark.svg"
                            alt="question mark"
                            className="cursor-help ml-2"
                          />
                        </Tooltip>
                        {/* <div className="ml-2 flex group relative">
                          <span
                            className="cursor-help w-[20px]"
                            id="tooltip-target-link-expiration"
                          >
                            <img
                              src="/assets/images/home/qmark.svg"
                              alt="question mark"
                            />
                          </span>
                          <div
                            id="tooltip-link-expiration"
                            role="tooltip"
                            className="hidden absolute z-[1212] text-white bg-[#333] p-2 border rounded shadow-lg text-sm min-w-max whitespace-normal"
                          >
                            <div className="tooltip-content">
                              Set expiration dates for shared links
                            </div>
                            <div id="arrow-transfer" data-popper-arrow="" />
                          </div>
                        </div> */}
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                    </tr>
                    <tr className="hover:bg-gray-100 border-b border-[#6B72804D]">
                      <td className="feature-td font-size-16 pl-[20px] lg:pl-[2vw] py-3 relative flex items-center whitespace-nowrap">
                        GEO location lock
                        <Tooltip
                          title={
                            <span style={{ fontSize: "15px" }}>
                              Restrict link access to specified countries
                            </span>
                          }
                          placement="top"
                        >
                          <img
                            src="/assets/images/home/qmark.svg"
                            alt="question mark"
                            className="cursor-help ml-2"
                          />
                        </Tooltip>
                        {/* <div className="ml-2 flex group relative">
                          <span
                            className="cursor-help w-[20px]"
                            id="tooltip-target-geo"
                          >
                            <img
                              src="/assets/images/home/qmark.svg"
                              alt="question mark"
                            />
                          </span>
                          <div
                            id="tooltip-geo"
                            role="tooltip"
                            className="hidden absolute z-[1212] text-white bg-[#333] p-2 border rounded shadow-lg text-sm min-w-max whitespace-normal"
                          >
                            <div className="tooltip-content">
                              Restrict link access to specified countries
                            </div>
                            <div id="arrow-transfer" data-popper-arrow="" />
                          </div>
                        </div> */}
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                    </tr>
                    {/* <tr className="hover:bg-gray-100 border-b border-[#6B72804D]">
                      <td className="feature-td font-size-16 pl-[20px] lg:pl-[2vw] py-3 relative flex items-center whitespace-nowrap">
                        Drag-and-Drop Bulk File Upload
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                    </tr> */}
                    <tr className="hover:bg-gray-100 border-b border-[#6B72804D]">
                      <td className="feature-td font-size-16 pl-[20px] lg:pl-[2vw] py-3 relative flex items-center whitespace-nowrap">
                        PDF Preview (Other Files: Download Only)
                        <Tooltip
                          title={
                            <span style={{ fontSize: "15px" }}>
                              In-app preview for PDFs; other files are
                              download-only
                            </span>
                          }
                          placement="top"
                        >
                          <img
                            src="/assets/images/home/qmark.svg"
                            alt="question mark"
                            className="cursor-help ml-2"
                          />
                        </Tooltip>
                        {/* <div className="ml-2 flex group relative">
                          <span
                            className="cursor-help w-[20px]"
                            id="tooltip-target-pdf"
                          >
                            <img
                              src="/assets/images/home/qmark.svg"
                              alt="question mark"
                            />
                          </span>
                          <div
                            id="tooltip-pdf"
                            role="tooltip"
                            className="hidden absolute z-[1212] text-white bg-[#333] p-2 border rounded shadow-lg text-sm min-w-max whitespace-normal"
                          >
                            <div className="tooltip-content">
                              In-app preview for PDFs; other files are download-only
                            </div>
                            <div id="arrow-transfer" data-popper-arrow="" />
                          </div>
                        </div> */}
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                    </tr>
                    <tr className="hover:bg-gray-100 border-b border-[#6B72804D]">
                      <td className="feature-td font-size-16 pl-[20px] lg:pl-[2vw] py-3 relative flex items-center whitespace-nowrap">
                        Location &amp; IP Address
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                    </tr>
                    <tr className="hover:bg-gray-100 border-b border-[#6B72804D]">
                      <td className="feature-td font-size-16 pl-[20px] lg:pl-[2vw] py-3 relative flex items-center whitespace-nowrap">
                        Email Notifications
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                    </tr>
                    <tr className="hover:bg-gray-100 border-b border-[#6B72804D]">
                      <td className="feature-td font-size-16 pl-[20px] lg:pl-[2vw] py-3 relative flex items-center whitespace-nowrap">
                        Print &amp; Download Permissions
                        <Tooltip
                          title={
                            <span style={{ fontSize: "15px" }}>
                              Control printing and downloading of shared files
                            </span>
                          }
                          placement="top"
                        >
                          <img
                            src="/assets/images/home/qmark.svg"
                            alt="question mark"
                            className="cursor-help ml-2"
                          />
                        </Tooltip>
                        {/* <div className="ml-2 flex group relative">
                          <span
                            className="cursor-help w-[20px]"
                            id="tooltip-target-print"
                          >
                            <img
                              src="/assets/images/home/qmark.svg"
                              alt="question mark"
                            />
                          </span>
                          <div
                            id="tooltip-print"
                            role="tooltip"
                            className="hidden absolute z-[1212] text-white bg-[#333] p-2 border rounded shadow-lg text-sm min-w-max whitespace-normal"
                          >
                            <div className="tooltip-content">
                              Control printing and downloading of shared files
                            </div>
                            <div id="arrow-transfer" data-popper-arrow="" />
                          </div>
                        </div> */}
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                    </tr>
                    <tr className="hover:bg-gray-100 border-b border-[#6B72804D]">
                      <td className="feature-td font-size-16 pl-[20px] lg:pl-[2vw] py-3 relative flex items-center whitespace-nowrap">
                        AI facial recognition
                        <Tooltip
                          title={
                            <span style={{ fontSize: "15px" }}>
                              AI will detect facial presence for file access
                              verification
                            </span>
                          }
                          placement="top"
                        >
                          <img
                            src="/assets/images/home/qmark.svg"
                            alt="question mark"
                            className="cursor-help ml-2"
                          />
                        </Tooltip>
                        {/* <div className="ml-2 flex group relative">
                          <span
                            className="cursor-help w-[20px]"
                            id="tooltip-target-ai"
                          >
                            <img
                              src="/assets/images/home/qmark.svg"
                              alt="question mark"
                            />
                          </span>
                          <div
                            id="tooltip-ai"
                            role="tooltip"
                            className="hidden absolute z-[1212] text-white bg-[#333] p-2 border rounded shadow-lg text-sm min-w-max whitespace-normal"
                          >
                            <div className="tooltip-content">
                              AI will detect facial presence for file access
                              verification
                            </div>
                            <div id="arrow-transfer" data-popper-arrow="" />
                          </div>
                        </div> */}
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        -
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        -
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                    </tr>
                    <tr className="hover:bg-gray-100 border-b border-[#6B72804D]">
                      <td className="feature-td font-size-16 pl-[20px] lg:pl-[2vw] py-3 relative flex items-center whitespace-nowrap">
                        Add-ons (storage, transfer, teams & workspaces)
                        <Tooltip
                          title={
                            <span style={{ fontSize: "15px" }}>
                              Purchase additional storage and transfer capacity
                            </span>
                          }
                          placement="top"
                        >
                          <img
                            src="/assets/images/home/qmark.svg"
                            alt="question mark"
                            className="cursor-help ml-2"
                          />
                        </Tooltip>
                        {/* <div className="ml-2 flex group relative">
                          <span
                            className="cursor-help w-[20px]"
                            id="tooltip-target-addons"
                          >
                            <img
                              src="/assets/images/home/qmark.svg"
                              alt="question mark"
                            />
                          </span>
                          <div
                            id="tooltip-addons"
                            role="tooltip"
                            className="hidden absolute z-[1212] text-white bg-[#333] p-2 border rounded shadow-lg text-sm min-w-max whitespace-normal"
                          >
                            <div className="tooltip-content">
                              Purchase additional storage and transfer capacity
                            </div>
                            <div id="arrow-transfer" data-popper-arrow="" />
                          </div>
                        </div> */}
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        -
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        -
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        -
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />
                      </td>
                    </tr>
                    <tr className="hover:bg-gray-100 border-b border-[#6B72804D]">
                      <td className="feature-td font-size-16 pl-[20px] lg:pl-[2vw] py-3 relative flex items-center whitespace-nowrap">
                        Predefined receivers
                        <Tooltip
                          title={
                            <span style={{ fontSize: "15px" }}>
                              Invite trusted contacts for facial-verified file
                              access
                            </span>
                          }
                          placement="top"
                        >
                          <img
                            src="/assets/images/home/qmark.svg"
                            alt="question mark"
                            className="cursor-help ml-2"
                          />
                        </Tooltip>
                        {/* <div className="ml-2 flex group relative">
                          <span
                            className="cursor-help w-[20px]"
                            id="tooltip-target-predefined"
                          >
                            <img
                              src="/assets/images/home/qmark.svg"
                              alt="question mark"
                            />
                          </span>
                          <div
                            id="tooltip-predefined"
                            role="tooltip"
                            className="hidden absolute z-[1212] text-white bg-[#333] p-2 border rounded shadow-lg text-sm min-w-max whitespace-normal"
                          >
                            <div className="tooltip-content">
                              Invite trusted contacts for facial-verified file access
                            </div>
                            <div id="arrow-transfer" data-popper-arrow="" />
                          </div>
                        </div> */}
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        -
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        -
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        -
                      </td>
                      <td className=" px-6 py-3 whitespace-nowrap font-size-16">
                        <img
                          className=" "
                          src="/assets/images/home/checkmark.svg"
                          alt="checkmark"
                        />{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </section>
      </main>
      <SupportSection />
      <Footer />
    </div>
  );
}

export default Pricing;
