import logo from "./logo.svg";
import "./App.css";
import NavbarComponent from "./Components/Navbar/Navbar";
import LandingPage from "./Pages/LandingPage/LandingPage";
import UserIsLoginProvider from "./Context/UserIsLoginProvider";
import { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";
import { ga } from "./config";
import { Route, Routes, useLocation } from "react-router-dom"; // Import useLocation
import SSOLOGIN from "./Components/SSOLOGIN";
import { Button } from "react-bootstrap";
import Pricing from "./Pages/Pricing/Pricing";
import Footer from "./Components/Footer/Footer";
import SupportSection from "./Components/SupportSection/SupportSection";
import FAQs from "./Pages/FAQs.jsx/FAQs";
import TermsAndConditions from "./Pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./Pages/Privacy-Policy/PrivacyPolicy";
import authService from "./Services/authService";
import NavigateLoader from "./Components/Navbar/Modals/Login/NavigateLoader";
import NotFound from "./Pages/NotFound";
import userService from "./Services/userService";
import SSOLOGINAPP from "./Components/SSOLOGINAPP";
import Cookies from "./Components/Cookies/Cookies";

function App() {
  const toastOptions = {
    duration: 4000, // 5 seconds in milliseconds
  };
  const [success, setSuccess] = useState("");
  const [open, setOpen] = useState(false);
  const toggle = (pricingPage = false) => {
    if (pricingPage) {
      setActive("register");
    }
    setOpen(!open);
  };

  const location = useLocation(); // Get the current location

  const isCheckLoginRoute = () => {
    return location.pathname === "/checkLogin"; // Check if the current location is the checkLogin route
  };
  const [loading, setLoading] = useState(false);
  let currentUrl = location.pathname;
  if (currentUrl === "/") currentUrl = "";
  if (currentUrl) {
    currentUrl = currentUrl.replace("/", "");
  }

  currentUrl = "&path=" + currentUrl;
  let iframeUrl = `${ga.GA_APP}/checkLogin?redirect=true` + currentUrl;

  useEffect(() => {
    let token = localStorage.getItem("auth");
    if (!token && !isCheckLoginRoute()) {
      // Only check login before navigating if it's not the checkLogin route
      setLoading(true);
      authService
        .checkLoginBefore()
        .then((res) => {
          // console.log(res);
          if (res && res.success) {
            window.location.href = iframeUrl;
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [location]); // Add location to the dependency array to trigger effect on route change

  useEffect(() => {
    // Scroll to the top of the page whenever the location changes
    document.documentElement.scrollIntoView({
      behavior: "instant",
      block: "start",
    });
  }, [location]); // Add location to the dependency array to trigger effect on route change
  const [active, setActive] = useState("Login");

  // cookies
  const cookiesState = localStorage.getItem("cookiesState");

  return (
    <div>
      {loading && <NavigateLoader />}
      <Toaster toastOptions={toastOptions} />
      <div className="">
        <UserIsLoginProvider>
          <>
            {!isCheckLoginRoute() && (
              <NavbarComponent
                open={open}
                toggle={toggle}
                active={active}
                setActive={setActive}
              />
            )}
            <Routes>
              <Route path="*" element={<NotFound />} />
              <Route path="checkLogin" element={<SSOLOGIN />} />
              <Route path="checkLoginApp" element={<SSOLOGINAPP />} />
              <Route path="pricing" element={<Pricing toggle={toggle} />} />
              <Route path="FAQs" element={<FAQs />} />
              <Route
                path="terms-and-conditions"
                element={<TermsAndConditions />}
              />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/"
                index
                element={<LandingPage open2={open} toggle={toggle} />}
              />
            </Routes>
          </>
        </UserIsLoginProvider>
        {!cookiesState && !isCheckLoginRoute() && <Cookies />}
      </div>
    </div>
  );
}

export default App;
