
import React from 'react'
import { Link } from 'react-router-dom'
import { ga } from '../../config'

function Footer() {
    return (
        <div>
            <footer class="bg-[#18181B]">
                <div
                    class="flex-wrap gap-x-[2vw] sm:gap-y-[2vw] gap-y-[4vw] container1720 flex justify-between items-center flex-col-reverse sm:flex-row py-4"
                >
                    <a
                        href={ga.GA_APP}
                        target='_blank'
                        class="lg:text-[0.83333333333vw] lg:leading-[0.88541666666vw] sm:text-[2.08333333333vw] sm:leading-[2.21354166667vw] text-[14px] text-[#9FA6B2] text-white"
                    >© 2024 GrandAutomation. All rights reserved.</a>
                    <ul
                        class="hidden flex items-center sm:gap-x-[3vw] gap-x-[6vw] lg:gap-x-[1.14583333333vw]"
                    >
                        <li>
                            <a href="" target="_blank">
                                <img
                                    src="./assets/images/icons/social/facebook.svg"
                                    alt="facebook"
                                    class="lg:w-[0.41244791666vw]"
                                />
                            </a>
                        </li>
                        <li>
                            <a href="" target="_blank">
                                <img
                                    src="./assets/images/icons/social/twitter.svg"
                                    alt="twitter"
                                    class="lg:w-[0.81395833333vw]"
                                />
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.instagram.com/grandtransfer.io/"
                                target="_blank"
                            >
                                <img
                                    src="./assets/images/icons/social/instagram.svg"
                                    alt="instagram"
                                    class="lg:w-[0.73197916666vw]"
                                />
                            </a>
                        </li>
                        <li>
                            <a href="" target="_blank">
                                <img
                                    src="./assets/images/icons/social/linkedin.svg"
                                    alt="linkedin"
                                    class="lg:w-[0.74239583333vw]"
                                />
                            </a>
                        </li>
                    </ul>
                    <div
                        class="text-[#F4F5F7] flex items-center lg:gap-x-[1.40625vw] sm:gap-x-[3vw] gap-x-[6vw] capitalize lg:text-[0.9375vw] lg:leading-[1.45833333333vw] sm:text-[2.34375vw] sm:leading-[3.64583333333vw] text-[14px]"
                    >
                        <Link to='https://grandautomation.io/terms-and-conditions'
                            onClick={() => window.scrollTo(0, 0)}
                            // open in new tab
                            target="_blank"
                        >terms & conditions</Link>
                        <Link onClick={() => window.scrollTo(0, 0)} target='_blank' to='https://grandautomation.io/privacy-policy' >privacy policy</Link>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer
