import React, { Fragment, useRef, useContext, useEffect, useState } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import { MdOutlineErrorOutline } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { appData, ga } from "../../../../config";
import { Dialog, Transition } from '@headlessui/react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import logo from '../../../../images/ProductsLogo/DarkBg/Frame.svg'
function LoginPage({ submit, app, loading, setLoading, redirect = false, toggle, open, setActive }) {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();
  const [showLogin, setShowLogin] = useState(false)
  const [continueLogin, setContinueLogin] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("this is an inavlid email")
        .required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your password"),
    }),
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      // send request to the server
      // if successfull
      // store the token in local storage
      // redirect to the dashboard
      // if failed
      // show error message
      const data = {
        email: values.email,
        password: values.password,
        app: app,
      };
      https://api-stage.grandautomation.io
      if (continueLogin) {
        data.continue_login = true;
      }

      setLoading(true);
      setError(false);

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };
      // submit the data
      fetch("https://api.grandautomation.io/api/login", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data, "data");
          // console.log(data.data.status, "token");

          if (data.success) {
            if (data.data.login) {
              setShowLogin(true)
              return;
            }

            const token = data.data.token;
            const iframeUrl = `${ga.GA_APP}/sso?token=${encodeURIComponent(
              token
            )}`;
            // localStorage.setItem("auth", JSON.stringify(data.data));

            // Open new window with the URL without showing it
            // const newWindow = window.open(iframeUrl, '_blank', 'height=1,width=1,left=-1000,top=-1000');
            // newWindow.blur();
            // newWindow.opener.focus();

            // console.log(data, data.data.email_verified, "tesst")

            // Open new window with a size of 0x0 pixels
            if (!redirect && data.data.email_verified) {

              const screenWidth = window.screen.width;
              const screenHeight = window.screen.height;
              const windowWidth = 600; // Width of the new window
              const windowHeight = 400; // Height of the new window

              const left = Math.max(0, (screenWidth - windowWidth) / 2);
              const top = Math.max(0, (screenHeight - windowHeight) / 2);

              const newWindow = window.open(
                iframeUrl,
                "winname",
                `toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=${left},top=${top},width=${windowWidth},height=${windowHeight}`
              );
              try {


                newWindow.document.write("<html><body></body></html>");

                // Set window location to the URL
                newWindow.location.href = iframeUrl;

                if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
                  // Popup blocked or not supported, fallback to opening in the current tab
                  let currentUrl = window.location.href;
                  let url = appData[0].url;

                  window.location.href = iframeUrl + "&redirect=true" + "&url=" + url + "&redirectPage=" + currentUrl;
                } else {
                  // Popup opened successfully, wait and then close it
                  newWindow.document.write("<html><body></body></html>");
                  setTimeout(() => {
                    newWindow.close();
                  }, 1000); // Adjust the delay if needed
                }

                setTimeout(() => {
                  newWindow.close();
                }, 1000); // Adjust the delay if needed
              } catch (error) {
                let currentUrl = window.location.href;
                let url = appData[0].url;

                window.location.href = iframeUrl + "&redirect=true" + "&url=" + url + "&redirectPage=" + currentUrl;

              }
            }

            // Close the window immediately after redirecting

            setSuccess("Login Successful! Redirecting to Dashboard...");

            submit(data.data);
          } else {
            setError(data.error.message);
            submit({ status: "error", message: data.error.message })
          }
        })
        .catch((error) => {

          console.error("Error:", error);
        })
        .finally(() => {
          setLoading(false);
          // submit(data);
        });
    },
  });

  const onContinue = () => {
    // submit the form
    setContinueLogin(true)
    setShowLogin(false)
    formik.handleSubmit()

  }
  const onCancel = () => {
    setShowLogin(false)
    setLoading(false)
  }
  const cancelButtonRef = useRef(null)

  return (

    <div className=" flex items-center justify-center flex-1 ">
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        show={showLogin}
        centered
      >

        <Modal.Body className='bg-white p-4 rounded-lg'
        >
          <div>
            <div style={{ marginTop: '0.75rem', textAlign: 'center' }}>
              <h3 style={{ fontSize: '1rem', fontWeight: '600', lineHeight: '1.5', color: '#1f2937' }}>
                You are login with same data on another device
              </h3>
              <div style={{ marginTop: '0.5rem' }}>
                <p style={{ fontSize: '0.875rem', color: '#6b7280' }}>
                  If you continue, you will be logged out of the other device.
                </p>
              </div>
            </div>
          </div>
          <div style={{ marginTop: '1.25rem', display: 'grid', gridTemplateRows: 'dense', gridTemplateColumns: 'repeat(2, 1fr)', gap: '0.75rem' }}>
            <button
              type="button"
              style={{
                width: '100%', justifyContent: 'center', borderRadius: '0.375rem', backgroundColor: '#ffffff', padding: '0.5rem 0.75rem',
                fontSize: '0.875rem', fontWeight: '600', color: '#1f2937', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)', borderColor: '#d1d5db',
                marginTop: '0', // because it's the first column in small screens
                borderStyle: 'solid', borderWidth: '1px'
              }}
              onClick={() => onCancel()}
            >
              Cancel
            </button>
            <button
              type="button"
              style={{
                width: '100%', justifyContent: 'center', borderRadius: '0.375rem', backgroundColor: '#4c4c4c', padding: '0.5rem 0.75rem',
                fontSize: '0.875rem', fontWeight: '600', color: '#ffffff', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',

              }}
              onClick={() => onContinue()}
            >
              Continue
            </button>

          </div>

        </Modal.Body>

      </Modal>
      <div className="">

        <iframe id="targetIframe" style={{ display: "none" }}></iframe>
        {/* 
        {error && (
          <div
            className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
            role="alert"
          >
            <span class="font-medium">{error}</span>
          </div>
        )}
        {success && (
          <div
            className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
            role="alert"
          >
            <span class="font-medium">{success}</span>
          </div>
        )} */}
        <form onSubmit={formik.handleSubmit} className="text-white formWidth m-auto">

          <div className="flex flex-col mb-4">

            <div
              className="d-sm-flex d-none"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={logo}
                alt=""
                style={{ width: "10rem", marginBottom: '4rem' }}
                className="logoResponsive"
              />
            </div>

            <div>
              <h5 className="m-0">LOGIN</h5>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ fontSize: "1rem", fontWeight: "200" }} className="paragPadding">
                  One account for all our products
                </p>
              </div>
            </div>

          </div>


          <div className="">
            <div className="w-full mt-10">
              <div className="flex">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 me-1"
                >
                  Email Address
                </label>
                <span className="starColor">*</span>
              </div>
              <div className="mt-2 relative">
                <input
                  type="text"
                  name="email"
                  id="email"
                  className={formik.errors.email && formik.touched.email ? "loginInputsInValid" : "loginInputsValid"}
                  placeholder="Enter Your Email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>

              {formik.errors.email && formik.touched.email ? (
                <div
                  style={{
                    marginTop: "0.2rem",
                    color: "#f80000",
                    fontSize: "0.875rem",
                  }}
                >
                  {formik.errors.email}
                </div>
              ) : null}
            </div>
          </div>

          <div className="mt-4">
            <div className="flex">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 me-1"
              >
                Password
              </label>
              <span className="starColor">*</span>
            </div>

            <div className="my-2 relative">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                className={formik.errors.password && formik.touched.password ? "loginInputsInValid" : "loginInputsValid"}
                placeholder="Enter Password Here"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            {formik.errors.password && formik.touched.password ? (
              <div
                style={{
                  marginTop: "0.2rem",
                  color: "#f80000",
                  fontSize: "0.875rem",
                }}
              >
                {formik.errors.password}
              </div>
            ) : null}
            <div className="flex items-center mt-4">
              <button
                disabled={loading}
                type="submit"
                style={{
                  width: "100%",
                  color: "white",
                  backgroundColor: "#4c4c4c",
                  borderRadius: "0.5rem",
                  fontSize: "0.875rem",
                  padding: "0.625rem",
                  textAlign: "center",
                  outline: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Loading...
                  </div>
                ) : (
                  "Login"
                )}
              </button>
            </div>

            <div className="text-sm mt-3">
              <Link to={'/'} onClick={() => setActive('forgetPassword')}
                className="font-semibold underline">
                Forgot password?
              </Link>
            </div>
          </div>
        </form>
      </div>

    </div>

  );
}

export default LoginPage;