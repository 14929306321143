


import React, { useEffect } from 'react'
import AOS from "aos";
import SupportSection from '../../Components/SupportSection/SupportSection.jsx';
import Footer from '../../Components/Footer/Footer.jsx';

function TermsAndConditions() {

    useEffect(() => {
        AOS.init({
            duration: 800,
            easing: 'ease',
            once: 'true', // whether animation should happen only once - while scrolling down
            mirror: true,
        });
    }, []);

    return (
        <div>
            <main>
                <section className="bg-black relative">
                    <div className="container1720">
                        <div className="text-[#FFF] relative z-[22] lg:w-[41.5625vw] pt-[35vw] pb-[15vw] sm:pt-[28vw] sm:pb-[15vw] lg:pb-[8.28125vw] lg:pt-[14.4270833333vw]">
                            <h1 data-aos="fade-up" className="text60">
                                Terms &amp; Conditions
                            </h1>
                        </div>
                        <div className="hidden lg:block absolute z-[1] right-0 bottom-0">
                            <img
                                className="h-[150px] lg:h-[23vw]"
                                src="/assets/images/home/banana.png"
                                alt="Terms & Conditions"
                            />
                        </div>
                    </div>
                </section>
                <section className='termsSection'>
                    <div className="text22 text-[#18181B] lg:pt-[6.51041666667vw] pt1 lg:w-[62.2395833333vw] mx-[auto] sm:w-[87%] w-[87%] flex flex-col sm:gap-y-[2.4166666667vw] gap-y-[6.3846153846vw] lg:gap-y-[1.66666666667vw] fs-5">

                        <div>
                            <h5 className='fw-bold mb-1'>Introduction</h5>
                            <p>Welcome to Grand Automation. By accessing our website and using our services, you agree to
                                comply with and be bound by the following terms and conditions.</p>
                        </div>

                        <div>
                            <h5 className='fw-bold mb-1'>Accounts</h5>
                            <p>You are responsible for maintaining the security of your account and password. Grand Automation
                                cannot and will not be liable for any loss or damage from your failure to comply with this security
                                obligation</p>
                        </div>

                        <div>
                            <h5 className='fw-bold mb-1'>Use of Service</h5>
                            <p>You may use our services only as permitted by law. You may not misuse our services in any way.</p>
                        </div>

                        <div>
                            <h5 className='fw-bold mb-1'>Intellectual Property</h5>
                            <p>All contents of the site are the exclusive property of Grand Automation or its licensors..</p>
                        </div>

                        <div>
                            <h5 className='fw-bold mb-1'>Service Availability</h5>
                            <p>We aim to provide the services 24/7, however, we are not liable if the website is unavailable at any
                                time</p>
                        </div>

                        <div>
                            <h5 className='fw-bold mb-1'>Termination</h5>
                            <p>We may terminate your access to the services without cause or notice, which may result in the
                                forfeiture and destruction of all information associated with your account.</p>
                        </div>

                        <div>
                            <h5 className='fw-bold mb-1'>Limitation of Liability</h5>
                            <p>In no event will Grand Automation, nor its directors, employees, partners, suppliers, or affiliates,
                                be liable for any indirect, incidental, special, consequential or punitive damages, including without
                                limitation, loss of profits, data, use, goodwill, or other intangible losses.</p>
                        </div>
                    </div>
                </section>
            </main>
            <SupportSection />
      <Footer />
        </div>
    )
}

export default TermsAndConditions
