import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { userIsLoginContext } from "../../../../Context/UserIsLoginProvider";
import toast from "react-hot-toast";
import userService from "../../../../Services/userService";
import GrandAutomationLogo from "../../../../images/ProductsLogo/DarkBg/Frame.svg";
import axios from "axios";
import { appData, ga } from "../../../../config";

function OtpPage({ toggle, token, resetToken, setResetToken, setActive }) {
  const nav = useNavigate();
  const [allDigitsFilled, setAllDigitsFilled] = useState(false); // State to track if all digits are filled
  const { authData, setAuthData } = useContext(userIsLoginContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  let tokenMap = sessionStorage.getItem("tokenMap");

  useEffect(() => {
    if (resetToken) {
      if (tokenMap && resetToken) {
        let tokenMapObj = JSON.parse(tokenMap);
        // console.log(tokenMapObj, resetToken);
        if (tokenMapObj.token != resetToken) {
          nav("/login");
        } else {
          setEmail(tokenMapObj.email);
        }
      } else {
        nav("/login");
      }
    }
  }, [resetToken, tokenMap]);

  useEffect(() => {
    // Add paste event listener to the document body
    document.body.addEventListener("paste", handlePaste);

    return () => {
      document.body.removeEventListener("paste", handlePaste);
    };
  }, []);

  const [loading, setLoading] = useState(true);
  const [timer, setTimer] = useState(59);
  const [resending, setResending] = useState(false);

  const digit1Ref = useRef(null);
  const digit2Ref = useRef(null);
  const digit3Ref = useRef(null);
  const digit4Ref = useRef(null);

  useEffect(() => {
    if (!authData?.email_verified_at) {
      setLoading(false);
    } else {
      toggle();
    }
  }, [authData, nav]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
      } else {
        setResending(false);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  const getFirstName = (name) => {
    if (resetToken) return "";
    if (!name) return "loading...";
    return name.split(" ")[0];
  };

  const [resendLoading, setResendLoading] = useState(false);

  const resendOtp = async () => {
    setResendLoading(true);
    const response = await userService.resendOtp();

    if (response.success) {
      toast.success(response.message);
      setResendLoading(false);
      setResending(true);
      setTimer(59);
    } else {
      toast.error(response.error.message);
      setResendLoading(false);
    }

    // Additional logic to resend OTP
  };

  const handleInputChange = (e, ref) => {
    const input = e.target;
    const maxLength = parseInt(input.getAttribute("maxlength"), 10);

    if (input.value.length >= maxLength && ref) {
      ref.current.focus();
    }

    // check if is digit 4 and all digits are filled call submit
    if (input.name === "digit4" && input.value.length === 1) {
      handleSubmit();
    }
  };

  const handlePaste = (e) => {
    const pastedText = e.clipboardData.getData("text/plain");
    const otpRegex = /\b\d{4}\b/; // Regular expression to match 4-digit OTP

    if (otpRegex.test(pastedText)) {
      const otpDigits = pastedText.match(otpRegex)[0].split("");

      if (otpDigits.length === 4) {
        // Fill input fields with OTP digits
        digit1Ref.current.value = otpDigits[0];
        digit2Ref.current.value = otpDigits[1];
        digit3Ref.current.value = otpDigits[2];
        digit4Ref.current.value = otpDigits[3];

        // Automatically submit the form
        handleSubmit();
      }
    }
  };

  const [loadingSend, setLoadingSend] = useState(false);

  const handleSubmit = async () => {
    try {
      const digit1 = digit1Ref.current.value;
      const digit2 = digit2Ref.current.value;
      const digit3 = digit3Ref.current.value;
      const digit4 = digit4Ref.current.value;
      setLoadingSend(true);
      if (!digit1 || !digit2 || !digit3 || !digit4) {
        toast.error("Please enter OTP");
        setLoadingSend(false);
        return;
      }

      let otp = digit1 + digit2 + digit3 + digit4;

      // convert to number

      if (resetToken) {
        let tokenMapObj = JSON.parse(tokenMap);
        const data = { email: tokenMapObj.email, otp: otp };
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
        // submit the data
        axios
          .post(
            "https://api.grandautomation.io/api/reset-password-verify-otp",
            data
          )
          .then((data) => {
            // console.log(data, "data");
            // console.log(data.data.status, "token");

            if (data.data.success) {
              toast.success(data.data.message);
              sessionStorage.removeItem("tokenMap");
              setResetToken(data.data.data.token);
              setActive("ResetPassword");
              setLoadingSend(false);
            }

            return;
          })
          .catch((e) => {
            toast.error("Something went wrong");
            // console.log(e);
            setLoadingSend(false);
          });
        return;
      } else {
        const response = await userService.verifyEmail(otp);

        if (response.success) {
          toast.success(response.message);
          // console.log(response, "data");
          setAuthData((prevData) => ({
            ...prevData,
            email_verified_at: new Date().toISOString(),
          }));

          // update local storage
          localStorage.setItem("auth", JSON.stringify(response.data));

          setLoadingSend(false);
          const token = response.data.token;
          const iframeUrl = `${ga.GA_APP}/sso?token=${encodeURIComponent(
            token
          )}`;

          // Open new window with the URL without showing it
          // const newWindow = window.open(iframeUrl, '_blank', 'height=1,width=1,left=-1000,top=-1000');
          // newWindow.blur();
          // newWindow.opener.focus();

          // Open new window with a size of 0x0 pixels

          const screenWidth = window.screen.width;
          const screenHeight = window.screen.height;
          const windowWidth = 600; // Width of the new window
          const windowHeight = 400; // Height of the new window

          const left = Math.max(0, (screenWidth - windowWidth) / 2);
          const top = Math.max(0, (screenHeight - windowHeight) / 2);

          try {
            const newWindow = window.open(
              iframeUrl,
              "winname",
              `toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=${left},top=${top},width=${windowWidth},height=${windowHeight}`
            );

            newWindow.document.write("<html><body></body></html>");

            // Set window location to the URL
            newWindow.location.href = iframeUrl;

            if (
              !newWindow ||
              newWindow.closed ||
              typeof newWindow.closed === "undefined"
            ) {
              // Popup blocked or not supported, fallback to opening in the current tab

              alert(
                "Popup blocked or not supported, fallback to opening in the current tab"
              );

              let currentUrl = window.location.href;
              let url = appData[0].url;

              window.location.href =
                iframeUrl +
                "&redirect=true" +
                "&url=" +
                url +
                "&redirectPage=" +
                currentUrl;
              toggle();
            } else {
              // Popup opened successfully, wait and then close it
              newWindow.document.write("<html><body></body></html>");
              setTimeout(() => {
                newWindow.close();
                toggle();
              }, 1000); // Adjust the delay if needed
            }
          } catch (error) {
            toggle();
            let currentUrl = window.location.href;
            let url = appData[0].url;
            window.location.href =
              iframeUrl +
              "&redirect=true" +
              "&url=" +
              url +
              "&redirectPage=" +
              currentUrl;
            // console.log(error);
            // window.location.href = iframeUrl + "&redirect=true" + "&url=" + appData[0].url;
          }

          // Close the window immediately after redirecting
        } else {
          toast.error(response.error.message);
          setLoadingSend(false);
        }
      }
    } catch (error) {
      // console.log(error);
      toast.error(error.message);
      setLoadingSend(false);
    }

    // Additional logic to handle OTP submission
  };

  useEffect(() => {
    if (token) {
      // add token to the header globally

      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      // update in local storage
      //   localStorage.setItem("token", token);

      userService
        .getUserData()
        .then((res) => {
          if (res.success) {
            setName(res.data.name);
            setEmail(res.data.email);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [token]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        width: "91.666667%",
        padding: "1rem",
        margin: "3rem auto",
      }}
      className="h-100"
    >
      <div>
        {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h6 style={{ fontSize: '3rem' }}>Welcome! {getFirstName(authData?.name)}</h6>
                    <img src={GrandAutomationLogo} alt="Grand Automation Logo" style={{ width: '100px', height: '100px' }} />
                </div> */}
        <div className="flex flex-col-reverse justify-between mb-10 headerOtp">
          <div className="">
            <h6
              style={{ fontSize: "1.5rem", fontWeight: "600" }}
              className="md:text-xl text-base font-semibold"
            >
              {resetToken ? "Reset Password" : " Welcome! "}

              {getFirstName(name)}
            </h6>
          </div>
          <div className="flex justify-center md:justify-start">
            <img
              src={GrandAutomationLogo}
              alt=""
              style={{ width: "10rem", marginBottom: "3rem" }}
              className="md:w-40 w-28 sm:mb-0 "
            />
          </div>
        </div>
        <div
          style={{ marginTop: "10px", fontWeight: "thin", color: "#b8b8b8" }}
        >
          {!resetToken && (
            <p style={{ marginBottom: "4px" }}>
              Thank you for registering with our service! We are excited to have
              you as a <br /> part of our community.
            </p>
          )}

          <div>
            <p style={{ marginBottom: "4px" }}>
              To complete your {resetToken ? "Reset Password" : "Registration"},
              please enter the One-Time Password (OTP) <br />
              that was sent to your email address:{" "}
              <span style={{ color: "white", fontWeight: "600" }}>
                {email ? email : authData?.email}
              </span>{" "}
              <br />
              {!resetToken &&
                "This is an important step in verifying your account and ensuring its security."}
            </p>

            {!resetToken && (
              <p>
                If you did not receive the OTP within an hour, please click on
                resend OTP to <br /> request a new one.
              </p>
            )}
          </div>

          {!resetToken && (
            <p style={{ marginBottom: "20px" }}>
              Thank you for choosing our service. We look forward to serving
              you!
            </p>
          )}
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            marginTop: "23px",
            gap: "4px",
          }}
        >
          <input
            type="text"
            name="digit1"
            id="digit1"
            maxLength="1"
            ref={digit1Ref}
            onInput={(e) => handleInputChange(e, digit2Ref)}
            style={{
              display: "block",
              borderRadius: "0.375rem",
              borderWidth: "0",
              backgroundColor: "transparent",
              boxShadow: "0 0 0 1px rgba(229, 231, 235, 0.1)",
              padding: "0.75rem",
              outline: "0",
              transition: "box-shadow 0.15s ease",
              width: "6.875rem",
              textAlign: "center",
              borderColor: "#E5E7EB1A",
            }}
            className="otpResponsive"
          />
          <input
            type="text"
            name="digit2"
            id="digit2"
            maxLength="1"
            ref={digit2Ref}
            onInput={(e) => handleInputChange(e, digit3Ref)}
            style={{
              display: "block",
              borderRadius: "0.375rem",
              borderWidth: "0",
              backgroundColor: "transparent",
              boxShadow: "0 0 0 1px rgba(229, 231, 235, 0.1)",
              padding: "0.75rem",
              outline: "0",
              transition: "box-shadow 0.15s ease",
              width: "6.875rem",
              textAlign: "center",
              borderColor: "#E5E7EB1A",
            }}
            className="otpResponsive"
          />
          <input
            type="text"
            name="digit3"
            id="digit3"
            maxLength="1"
            ref={digit3Ref}
            onInput={(e) => handleInputChange(e, digit4Ref)}
            style={{
              display: "block",
              borderRadius: "0.375rem",
              borderWidth: "0",
              backgroundColor: "transparent",
              boxShadow: "0 0 0 1px rgba(229, 231, 235, 0.1)",
              padding: "0.75rem",
              outline: "0",
              transition: "box-shadow 0.15s ease",
              width: "6.875rem",
              textAlign: "center",
              borderColor: "#E5E7EB1A",
            }}
            className="otpResponsive"
          />
          <input
            type="text"
            name="digit4"
            id="digit4"
            maxLength="1"
            ref={digit4Ref}
            onInput={(e) => handleInputChange(e, null)} // No need to move to next input after the fourth digit
            style={{
              display: "block",
              borderRadius: "0.375rem",
              borderWidth: "0",
              backgroundColor: "transparent",
              boxShadow: "0 0 0 1px rgba(229, 231, 235, 0.1)",
              padding: "0.75rem",
              outline: "0",
              transition: "box-shadow 0.15s ease",
              width: "6.875rem",
              textAlign: "center",
              borderColor: "#E5E7EB1A",
            }}
            className="otpResponsive"
          />
        </div>

        <div style={{ marginTop: "19px" }}>
          <button
            onClick={handleSubmit}
            style={{
              width: "95%",
              color: "white",
              backgroundColor: "#4C4C4C",
              borderRadius: "0.5rem",
              fontSize: "0.875rem",
              padding: "0.625rem",
              textAlign: "center",
              outline: "none",
              cursor: `${loadingSend ? "default" : "pointer"}`,
            }}
            disabled={loadingSend}
          >
            {loadingSend ? "Please Wait..." : "Verify Email"}
          </button>
        </div>

        {!resetToken && (
          <div
            style={{
              marginTop: "19px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p style={{ color: "#4C4C4C" }}>
              Didn't receive a code{" "}
              <button
                to=""
                style={{
                  fontWeight: "500",
                  textDecoration: "underline",
                  marginLeft: "2px",
                  color: `${resending || resendLoading ? "gray" : "white"}`,
                  cursor: `${
                    resending || resendLoading ? "default" : "pointer"
                  }`,
                }}
                onClick={resendOtp}
                disabled={resending || resendLoading}
              >
                {resending
                  ? `Resending OTP in ${timer} seconds`
                  : resendLoading
                  ? "Resending..."
                  : "Resend OTP"}
              </button>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default OtpPage;
