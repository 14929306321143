import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import NavigateLoader from "./Navbar/Modals/Login/NavigateLoader";
import { useNavigate } from "react-router-dom";
import ssoService from "../Services/ssoService";
import axios from "axios";

function SSOLOGINAPP() {
  // get token from url
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = localStorage.getItem("auth");
  const redirect = urlParams.get("redirect");
  const path = urlParams.get("path");
  const url = urlParams.get("redirectPage");

  // save token to local storage

  // redirect to dashboard
  // const nav = useNavigate();ك

  // alert(token)
  const handleProductClick = async (app) => {
    //
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(localStorage.getItem("auth")).token;

    ssoService
      .login("Gt")
      .then((res) => {
        if (res) {
          //   setRedirectUrl(res.redirect_url);
          //   redirectToUrl(res.redirect_url);
          //open res.redirect_url in new tab
          // add params to the url called wqx if plan = 1 => B5fG7hJ2kL8pZ9qR3sT6uY0vX4wE 2 => 2B5fG7hJ2kL8pZ9qR3sT6uY0vX4wE 3=> 3B5fG7hJ2kL8pZ9qR3sT6uY0vX4wE 4=> 4B5fG7hJ2kL8pZ9qR3sT6uY0vX4wE
          // and if  PlanState == mounthly add 1 else add 2

          window.location.href = res.redirect_url;
        }
      })
      .catch((error) => {
        window.location.href = "/";
        // console.log(error);
      });

 
  };

  if (token) {
    handleProductClick("Gt");
  } else {
    // clear local storage
    window.location.href = "/";
  }

  return (
    <>
      <NavigateLoader />
    </>
  );
}

export default SSOLOGINAPP;
