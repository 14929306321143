



import React, { useEffect, useState } from 'react'
import HomePageButton from '../../../Components/HomePageButton'
import axios from '../../../Services/axiosConfig';

import checkIcon from './icons/check.svg'



function TabContent({ PlanState, setPlanState, toggle, plansData }) {


    return (
        <div className="lg:pt-[5.20833333333vw] sm:pt-[8.4166666667vw] pt-[14.3846153846vw]">
            <div id="monthly" className="active">
                <div className="flex pricesGap flex-wrap">
                    {plansData.map((plan, index) => {
                        return <>
                            <div
                                data-aos="fade"
                                data-aos-delay={300 + index * 100}
                                className="rec priceCard mx-auto lg:w-[21.25vw] sm:w-[80%] w-full lg:py-[3.125vw] sm:py-[6vw] py-[10vw] border12 bg-[#F4F5F7] relative overflow-hidden paddingResponsive marginResponsive d-flex flex-column justify-content-between"
                            >
                                <div>
                                    <div>
                                        {plan.name === 'Pro' &&
                                            <div className="text22 text-[#FFFFFF] bg-[#992861] border12recomended transform rotate-[-90deg] absolute recommend__Fixer">
                                                <span> Recommended </span>
                                            </div>
                                        }

                                    </div>
                                    <div className=" mx-[auto] sm:w-[89%] lg:w-[75.50%] w-[80%] text-[#18181B]">
                                        <div>
                                            <div className="lg:h-[11.09375vw] mb-[12vw] sm:mb-[10vw] lg:mb-0">
                                                <h4 className="text45 d-flex align-items-center justify-content-between">
                                                    {plan.name}
                                                    {/* {plan.name == 'Free Trial' && <span className='textPrimary fs-6'>Current plan</span>} */}
                                                </h4>
                                                <div className="lg:mt-[0.52083333333vw] proTag mt1 flex lg:gap-x-[0.52083333333vw] sm:gap-x-[1.5vw] gap-x-[2vw]">
                                                    {plan.name === 'Free Trial' ? <div>For the first 12 months</div>
                                                        :
                                                        <>
                                                            <span className="text-[#6B7280] text35">
                                                                {PlanState == 'monthly' ?
                                                                    <del className="del">{`$${plan.original_monthly_price}`}</del>
                                                                    :
                                                                    <del className="del">{`$${plan.original_yearly_price}`}</del>
                                                                }

                                                            </span>

                                                            {PlanState == 'monthly' ?
                                                                <span className="lg:text-[2.86458333333vw] price lg:leading-[3.125vw] sm:text-[4.94791666667vw] sm:leading-[5.64583333333vw] text-[7.17948717949vw] leading-[9.17948717949vw]">
                                                                    {`$${plan.monthly_price}`}
                                                                </span>
                                                                :
                                                                <span className="lg:text-[2.86458333333vw] price lg:leading-[3.125vw] sm:text-[4.94791666667vw] sm:leading-[5.64583333333vw] text-[7.17948717949vw] leading-[9.17948717949vw]">
                                                                    {`$${plan.yearly_price}`}
                                                                </span>
                                                            }
                                                        </>
                                                    }


                                                </div>
                                                {plan.name == 'Free Trial' ? null :
                                                    <>
                                                        {PlanState == 'monthly' ?
                                                            <p className="text20new durationTag lg:leading-[1.5625vw] lg:mt-[0.52083333333vw] mt1"> Per month</p>
                                                            :
                                                            <p className="text20new durationTag lg:leading-[1.5625vw] lg:mt-[0.52083333333vw] mt1"> Annually</p>
                                                        }
                                                    </>
                                                }

                                            </div>
                                            <div>
                                                <h5 className="text22new lg:leading-[1.66666666667vw]">
                                                    Features include:
                                                </h5>
                                                <ul className="text-[#6B7280] lg:mt-[1.04166666667vw] mt1 flex flex-col lg:gap-y-[0.36458333333vw] sm:gap-y-[0.65104166666vw] gap-y-[2.05128205128vw] p-0">
                                                    {plan?.description
                                                        .split(", ")
                                                        .map((feature, index) => (
                                                            <li key={index} className="flex gap-x-3 text20new">
                                                                {/* <IconCheck
                                                            className="h-6 w-5 flex-none text-[#992861]"
                                                            aria-hidden="true"
                                                        /> */}
                                                                <img src={checkIcon} alt="" width={20} className='me-3' />
                                                                {feature}
                                                            </li>
                                                        ))}
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <HomePageButton PlanState={PlanState} setPlanState={setPlanState} isPricing={true} toggle={toggle} plan={plan.id} />
                            </div>
                        </>
                    })}
                    {/* <div
                        data-aos="fade"
                        className="mx-auto priceCard lg:w-[21.25vw] sm:w-[80%] w-full lg:py-[3.125vw] sm:py-[6vw] py-[10vw] border12 bg-[#F4F5F7] relative overflow-hidden paddingResponsive marginResponsive"
                    >
                        <div className="text22 text-[#FFFFFF] bg-[#992861] border12recomended transform rotate-[-90deg] absolute recommend__Fixer">
                            <span> Recommended </span>
                        </div>
                        <div className="lg:pb-[1.92708333333vw] sm:pb-[2vw] pb-[2vw] mx-[auto] sm:w-[89%] lg:w-[75.50%] w-[80%] text-[#18181B] flex flex-col items-start">
                            <div className="lg:h-[11.09375vw] mb-[12vw] sm:mb-[10vw] lg:mb-0">
                                <h4 className="text45">Free Trial</h4>
                                <p className="text20new lg:leading-[1.5625vw] lg:mt-[0.52083333333vw] mt1">
                                    For the first 12 months
                                </p>
                            </div>
                            <div>
                                <h5 className="text20new lg:leading-[1.66666666667vw]">
                                    Features include:
                                </h5>
                                <ul className="text-[#6B7280] flex flex-col lg:gap-y-[0.36458333333vw] sm:gap-y-[0.65104166666vw] gap-y-[2.05128205128vw] p-0">
                                    <li className="flex items-start lg:gap-x-[0.36458333333vw] sm:gap-x-[0.65104166666vw] gap-x-[2.05128205128vw]">
                                        <img
                                            src="./assets/images/icons/check.svg"
                                            alt="check"
                                            className="flipped lg:w-[1.35416666667vw] sm:w-[3.125vw] w-[5.64102564103vw]"
                                        />
                                        <span className="text18 lg:leading-[1.66666666667vw] block">
                                            Store up to 5 GB
                                        </span>
                                    </li>
                                    <li className="flex items-start lg:gap-x-[0.36458333333vw] sm:gap-x-[0.65104166666vw] gap-x-[2.05128205128vw]">
                                        <img
                                            src="./assets/images/icons/check.svg"
                                            alt="check"
                                            className="flipped lg:w-[1.35416666667vw] sm:w-[3.125vw] w-[5.64102564103vw]"
                                        />
                                        <span className="text18 lg:leading-[1.66666666667vw] block">
                                            Transfer up to 1 GB/monthly
                                        </span>
                                    </li>
                                    <li className="flex items-start lg:gap-x-[0.36458333333vw] sm:gap-x-[0.65104166666vw] gap-x-[2.05128205128vw]">
                                        <img
                                            src="./assets/images/icons/check.svg"
                                            alt="check"
                                            className="flipped lg:w-[1.35416666667vw] sm:w-[3.125vw] w-[5.64102564103vw]"
                                        />
                                        <span className="text18 lg:leading-[1.66666666667vw] block">
                                            1 Watermark Templates
                                        </span>
                                    </li>
                                    <li className="flex items-start lg:gap-x-[0.36458333333vw] sm:gap-x-[0.65104166666vw] gap-x-[2.05128205128vw]">
                                        <img
                                            src="./assets/images/icons/check.svg"
                                            alt="check"
                                            className="flipped lg:w-[1.35416666667vw] sm:w-[3.125vw] w-[5.64102564103vw]"
                                        />
                                        <span className="text18 lg:leading-[1.66666666667vw] block">
                                            1 Workspace
                                        </span>
                                    </li>
                                    <li className="flex items-start lg:gap-x-[0.36458333333vw] sm:gap-x-[0.65104166666vw] gap-x-[2.05128205128vw]">
                                        <img
                                            src="./assets/images/icons/check.svg"
                                            alt="check"
                                            className="flipped lg:w-[1.35416666667vw] sm:w-[3.125vw] w-[5.64102564103vw]"
                                        />
                                        <span className="text18 lg:leading-[1.66666666667vw] block">
                                            1 Team member
                                        </span>
                                    </li>
                                    <li className="flex items-start lg:gap-x-[0.36458333333vw] sm:gap-x-[0.65104166666vw] gap-x-[2.05128205128vw]">
                                        <img
                                            src="./assets/images/icons/check.svg"
                                            alt="check"
                                            className="flipped lg:w-[1.35416666667vw] sm:w-[3.125vw] w-[5.64102564103vw]"
                                        />
                                        <span className="text18 lg:leading-[1.66666666667vw] block">
                                            Unlimited Secure Sharing Links
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <HomePageButton setPlanState={setPlanState} PlanState={PlanState} isPricing={true} toggle={toggle} plan={1} />
                        </div>
                    </div>
                    <div
                        data-aos="fade"
                        data-aos-delay={150}
                        className="mx-auto priceCard lg:w-[21.25vw] sm:w-[80%] w-full lg:py-[3.125vw] sm:py-[6vw] py-[10vw] border12 bg-[#F4F5F7] relative overflow-hidden paddingResponsive marginResponsive"
                    >
                        <div className="text22 text-[#FFFFFF] bg-[#992861] border12recomended transform rotate-[-90deg] absolute recommend__Fixer">
                            <span> Recommended </span>
                        </div>
                        <div className="lg:pb-[1.92708333333vw] sm:pb-[2vw] pb-[2vw] mx-[auto] sm:w-[89%] lg:w-[75.50%] w-[80%] text-[#18181B] flex flex-col items-start">
                            <div className="lg:h-[11.09375vw] mb-[12vw] sm:mb-[10vw] lg:mb-0">
                                <h4 className="text45">Plus</h4>
                                <div className="lg:mt-[0.52083333333vw] plusTag mt1 flex lg:gap-x-[0.52083333333vw] sm:gap-x-[1.5vw] gap-x-[2vw]">
                                    <span className="text-[#6B7280] text35">

                                        {PlanState == 'monthly' ?
                                            <del className="del">$9</del>
                                            :
                                            <del className="del">$72</del>
                                        }

                                    </span>

                                    {PlanState == 'monthly' ?
                                        <span className="lg:text-[2.86458333333vw] price lg:leading-[3.125vw] sm:text-[4.94791666667vw] sm:leading-[5.64583333333vw] text-[7.17948717949vw] leading-[9.17948717949vw]">
                                            $6
                                        </span>
                                        :
                                        <span className="lg:text-[2.86458333333vw] price lg:leading-[3.125vw] sm:text-[4.94791666667vw] sm:leading-[5.64583333333vw] text-[7.17948717949vw] leading-[9.17948717949vw]">
                                            $66
                                        </span>
                                    }

                                </div>
                                {PlanState == 'monthly' ?
                                    <p className="text20new durationTag lg:leading-[1.5625vw] lg:mt-[0.52083333333vw] mt1"> Per month</p>
                                    :
                                    <p className="text20new durationTag lg:leading-[1.5625vw] lg:mt-[0.52083333333vw] mt1"> Annually</p>
                                }
                            </div>
                            <div>
                                <h5 className="text20new lg:leading-[1.66666666667vw]">
                                    Features include:
                                </h5>
                                <ul className="text-[#6B7280] lg:mt-[1.04166666667vw] mt1 flex flex-col lg:gap-y-[0.36458333333vw] sm:gap-y-[0.65104166666vw] gap-y-[2.05128205128vw] p-0">
                                    <li className="flex items-start lg:gap-x-[0.36458333333vw] sm:gap-x-[0.65104166666vw] gap-x-[2.05128205128vw]">
                                        <img
                                            src="./assets/images/icons/check.svg"
                                            alt="check"
                                            className="flipped lg:w-[1.35416666667vw] sm:w-[3.125vw] w-[5.64102564103vw]"
                                        />
                                        <span className="text18 lg:leading-[1.66666666667vw] block">
                                            Store up to 100 GB
                                        </span>
                                    </li>
                                    <li className="flex items-start lg:gap-x-[0.36458333333vw] sm:gap-x-[0.65104166666vw] gap-x-[2.05128205128vw]">
                                        <img
                                            src="./assets/images/icons/check.svg"
                                            alt="check"
                                            className="flipped lg:w-[1.35416666667vw] sm:w-[3.125vw] w-[5.64102564103vw]"
                                        />
                                        <span className="text18 lg:leading-[1.66666666667vw] block">
                                            Transfer up to 5 GB/monthly
                                        </span>
                                    </li>
                                    <li className="flex items-start lg:gap-x-[0.36458333333vw] sm:gap-x-[0.65104166666vw] gap-x-[2.05128205128vw]">
                                        <img
                                            src="./assets/images/icons/check.svg"
                                            alt="check"
                                            className="flipped lg:w-[1.35416666667vw] sm:w-[3.125vw] w-[5.64102564103vw]"
                                        />
                                        <span className="text18 lg:leading-[1.66666666667vw] block">
                                            10 Watermark Templates
                                        </span>
                                    </li>
                                    <li className="flex items-start lg:gap-x-[0.36458333333vw] sm:gap-x-[0.65104166666vw] gap-x-[2.05128205128vw]">
                                        <img
                                            src="./assets/images/icons/check.svg"
                                            alt="check"
                                            className="flipped lg:w-[1.35416666667vw] sm:w-[3.125vw] w-[5.64102564103vw]"
                                        />
                                        <span className="text18 lg:leading-[1.66666666667vw] block">
                                            2 Workspaces
                                        </span>
                                    </li>
                                    <li className="flex items-start lg:gap-x-[0.36458333333vw] sm:gap-x-[0.65104166666vw] gap-x-[2.05128205128vw]">
                                        <img
                                            src="./assets/images/icons/check.svg"
                                            alt="check"
                                            className="flipped lg:w-[1.35416666667vw] sm:w-[3.125vw] w-[5.64102564103vw]"
                                        />
                                        <span className="text18 lg:leading-[1.66666666667vw] block">
                                            5 Team members
                                        </span>
                                    </li>
                                    <li className="flex items-start lg:gap-x-[0.36458333333vw] sm:gap-x-[0.65104166666vw] gap-x-[2.05128205128vw]">
                                        <img
                                            src="./assets/images/icons/check.svg"
                                            alt="check"
                                            className="flipped lg:w-[1.35416666667vw] sm:w-[3.125vw] w-[5.64102564103vw]"
                                        />
                                        <span className="text18 lg:leading-[1.66666666667vw] block">
                                            Unlimited Secure Sharing Links
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <HomePageButton setPlanState={setPlanState} PlanState={PlanState} isPricing={true} toggle={toggle} plan={2} />

                        </div>
                    </div>
                    <div
                        data-aos="fade"
                        data-aos-delay={300}
                        className="rec priceCard mx-auto lg:w-[21.25vw] sm:w-[80%] w-full lg:py-[3.125vw] sm:py-[6vw] py-[10vw] border12 bg-[#F4F5F7] relative overflow-hidden paddingResponsive marginResponsive"
                    >
                        <div>
                            <div className="text22 text-[#FFFFFF] bg-[#992861] border12recomended transform rotate-[-90deg] absolute recommend__Fixer">
                                <span> Recommended </span>
                            </div>
                        </div>
                        <div className="lg:pb-[1.92708333333vw] sm:pb-[2vw] pb-[2vw] mx-[auto] sm:w-[89%] lg:w-[75.50%] w-[80%] text-[#18181B] flex flex-col items-start">
                            <div className="lg:h-[11.09375vw] mb-[12vw] sm:mb-[10vw] lg:mb-0">
                                <h4 className="text45">Pro</h4>
                                <div className="lg:mt-[0.52083333333vw] proTag mt1 flex lg:gap-x-[0.52083333333vw] sm:gap-x-[1.5vw] gap-x-[2vw]">
                                    <span className="text-[#6B7280] text35">
                                        {PlanState == 'monthly' ?
                                            <del className="del">$15</del>
                                            :
                                            <del className="del">$144</del>
                                        }

                                    </span>

                                    {PlanState == 'monthly' ?
                                        <span className="lg:text-[2.86458333333vw] price lg:leading-[3.125vw] sm:text-[4.94791666667vw] sm:leading-[5.64583333333vw] text-[7.17948717949vw] leading-[9.17948717949vw]">
                                            $12
                                        </span>
                                        :
                                        <span className="lg:text-[2.86458333333vw] price lg:leading-[3.125vw] sm:text-[4.94791666667vw] sm:leading-[5.64583333333vw] text-[7.17948717949vw] leading-[9.17948717949vw]">
                                            $132
                                        </span>
                                    }

                                </div>
                                {PlanState == 'monthly' ?
                                    <p className="text20new durationTag lg:leading-[1.5625vw] lg:mt-[0.52083333333vw] mt1"> Per month</p>
                                    :
                                    <p className="text20new durationTag lg:leading-[1.5625vw] lg:mt-[0.52083333333vw] mt1"> Annually</p>
                                }
                            </div>
                            <div>
                                <h5 className="text20new lg:leading-[1.66666666667vw]">
                                    Features include:
                                </h5>
                                <ul className="text-[#6B7280] lg:mt-[1.04166666667vw] mt1 flex flex-col lg:gap-y-[0.36458333333vw] sm:gap-y-[0.65104166666vw] gap-y-[2.05128205128vw] p-0">
                                    <li className="flex items-start lg:gap-x-[0.36458333333vw] sm:gap-x-[0.65104166666vw] gap-x-[2.05128205128vw]">
                                        <img
                                            src="./assets/images/icons/check.svg"
                                            alt="check"
                                            className="flipped lg:w-[1.35416666667vw] sm:w-[3.125vw] w-[5.64102564103vw]"
                                        />
                                        <span className="text18 lg:leading-[1.66666666667vw] block">
                                            Store up to 1 TB
                                        </span>
                                    </li>
                                    <li className="flex items-start lg:gap-x-[0.36458333333vw] sm:gap-x-[0.65104166666vw] gap-x-[2.05128205128vw]">
                                        <img
                                            src="./assets/images/icons/check.svg"
                                            alt="check"
                                            className="flipped lg:w-[1.35416666667vw] sm:w-[3.125vw] w-[5.64102564103vw]"
                                        />
                                        <span className="text18 lg:leading-[1.66666666667vw] block">
                                            Transfer up to 20 GB/monthly
                                        </span>
                                    </li>
                                    <li className="flex items-start lg:gap-x-[0.36458333333vw] sm:gap-x-[0.65104166666vw] gap-x-[2.05128205128vw]">
                                        <img
                                            src="./assets/images/icons/check.svg"
                                            alt="check"
                                            className="flipped lg:w-[1.35416666667vw] sm:w-[3.125vw] w-[5.64102564103vw]"
                                        />
                                        <span className="text18 lg:leading-[1.66666666667vw] block">
                                            25 Watermark Templates
                                        </span>
                                    </li>
                                    <li className="flex items-start lg:gap-x-[0.36458333333vw] sm:gap-x-[0.65104166666vw] gap-x-[2.05128205128vw]">
                                        <img
                                            src="./assets/images/icons/check.svg"
                                            alt="check"
                                            className="flipped lg:w-[1.35416666667vw] sm:w-[3.125vw] w-[5.64102564103vw]"
                                        />
                                        <span className="text18 lg:leading-[1.66666666667vw] block">
                                            5 Workspaces
                                        </span>
                                    </li>
                                    <li className="flex items-start lg:gap-x-[0.36458333333vw] sm:gap-x-[0.65104166666vw] gap-x-[2.05128205128vw]">
                                        <img
                                            src="./assets/images/icons/check.svg"
                                            alt="check"
                                            className="flipped lg:w-[1.35416666667vw] sm:w-[3.125vw] w-[5.64102564103vw]"
                                        />
                                        <span className="text18 lg:leading-[1.66666666667vw] block">
                                            10 team members
                                        </span>
                                    </li>
                                    <li className="flex items-start lg:gap-x-[0.36458333333vw] sm:gap-x-[0.65104166666vw] gap-x-[2.05128205128vw]">
                                        <img
                                            src="./assets/images/icons/check.svg"
                                            alt="check"
                                            className="flipped lg:w-[1.35416666667vw] sm:w-[3.125vw] w-[5.64102564103vw]"
                                        />
                                        <span className="text18 lg:leading-[1.66666666667vw] block">
                                            Unlimited Secure Sharing Links
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <HomePageButton PlanState={PlanState} setPlanState={setPlanState} isPricing={true} toggle={toggle} plan={3} />

                        </div>
                    </div>
                    <div
                        data-aos="fade"
                        data-aos-delay={450}
                        className="mx-auto priceCard lg:w-[21.25vw] sm:w-[80%] w-full lg:py-[3.125vw] sm:py-[6vw] py-[10vw] border12 bg-[#F4F5F7] relative overflow-hidden paddingResponsive"
                    >
                        <div className="text22 text-[#FFFFFF] bg-[#992861] border12recomended transform rotate-[-90deg] absolute recommend__Fixer">
                            <span> Recommended </span>
                        </div>
                        <div className="lg:pb-[1.92708333333vw] sm:pb-[2vw] pb-[2vw] mx-[auto] sm:w-[89%] lg:w-[75.50%] w-[80%] text-[#18181B] flex flex-col items-start">
                            <div className="lg:h-[11.09375vw] mb-[12vw] sm:mb-[10vw] lg:mb-0">
                                <h4 className="text45">Max</h4>
                                <div className="lg:mt-[0.52083333333vw] maxTag mt1 flex lg:gap-x-[0.52083333333vw] sm:gap-x-[1.5vw] gap-x-[2vw]">
                                    <span className="text-[#6B7280] text35">

                                        {PlanState == 'monthly' ?
                                            <del className="del">$30</del>
                                            :
                                            <del className="del">$300</del>
                                        }

                                    </span>

                                    {PlanState == 'monthly' ?
                                        <span className="lg:text-[2.86458333333vw] price lg:leading-[3.125vw] sm:text-[4.94791666667vw] sm:leading-[5.64583333333vw] text-[7.17948717949vw] leading-[9.17948717949vw]">
                                            $25
                                        </span>

                                        :
                                        <span className="lg:text-[2.86458333333vw] price lg:leading-[3.125vw] sm:text-[4.94791666667vw] sm:leading-[5.64583333333vw] text-[7.17948717949vw] leading-[9.17948717949vw]">
                                            $275
                                        </span>
                                    }

                                </div>

                                {PlanState == 'monthly' ?
                                    <p className="text20new durationTag lg:leading-[1.5625vw] lg:mt-[0.52083333333vw] mt1"> Per month</p>
                                    :
                                    <p className="text20new durationTag lg:leading-[1.5625vw] lg:mt-[0.52083333333vw] mt1"> Annually</p>
                                }

                            </div>
                            <div>
                                <h5 className="text20new lg:leading-[1.66666666667vw]">
                                    Features include:
                                </h5>
                                <ul className="text-[#6B7280] lg:mt-[1.04166666667vw] mt1 flex flex-col lg:gap-y-[0.36458333333vw] sm:gap-y-[0.65104166666vw] gap-y-[2.05128205128vw] p-0">
                                    <li className="flex items-start lg:gap-x-[0.36458333333vw] sm:gap-x-[0.65104166666vw] gap-x-[2.05128205128vw]">
                                        <img
                                            src="./assets/images/icons/check.svg"
                                            alt="check"
                                            className="flipped lg:w-[1.35416666667vw] sm:w-[3.125vw] w-[5.64102564103vw]"
                                        />
                                        <span className="text18 lg:leading-[1.66666666667vw] block">
                                            Store up to 3 TB
                                        </span>
                                    </li>
                                    <li className="flex items-start lg:gap-x-[0.36458333333vw] sm:gap-x-[0.65104166666vw] gap-x-[2.05128205128vw]">
                                        <img
                                            src="./assets/images/icons/check.svg"
                                            alt="check"
                                            className="flipped lg:w-[1.35416666667vw] sm:w-[3.125vw] w-[5.64102564103vw]"
                                        />
                                        <span className="text18 lg:leading-[1.66666666667vw] block">
                                            Transfer up to 50 GB/monthly
                                        </span>
                                    </li>
                                    <li className="flex items-start lg:gap-x-[0.36458333333vw] sm:gap-x-[0.65104166666vw] gap-x-[2.05128205128vw]">
                                        <img
                                            src="./assets/images/icons/check.svg"
                                            alt="check"
                                            className="flipped lg:w-[1.35416666667vw] sm:w-[3.125vw] w-[5.64102564103vw]"
                                        />
                                        <span className="text18 lg:leading-[1.66666666667vw] block">
                                            50 Watermark Templates
                                        </span>
                                    </li>
                                    <li className="flex items-start lg:gap-x-[0.36458333333vw] sm:gap-x-[0.65104166666vw] gap-x-[2.05128205128vw]">
                                        <img
                                            src="./assets/images/icons/check.svg"
                                            alt="check"
                                            className="flipped lg:w-[1.35416666667vw] sm:w-[3.125vw] w-[5.64102564103vw]"
                                        />
                                        <span className="text18 lg:leading-[1.66666666667vw] block">
                                            10 workspaces
                                        </span>
                                    </li>
                                    <li className="flex items-start lg:gap-x-[0.36458333333vw] sm:gap-x-[0.65104166666vw] gap-x-[2.05128205128vw]">
                                        <img
                                            src="./assets/images/icons/check.svg"
                                            alt="check"
                                            className="flipped lg:w-[1.35416666667vw] sm:w-[3.125vw] w-[5.64102564103vw]"
                                        />
                                        <span className="text18 lg:leading-[1.66666666667vw] block">
                                            20 team members
                                        </span>
                                    </li>
                                    <li className="flex items-start lg:gap-x-[0.36458333333vw] sm:gap-x-[0.65104166666vw] gap-x-[2.05128205128vw]">
                                        <img
                                            src="./assets/images/icons/check.svg"
                                            alt="check"
                                            className="flipped lg:w-[1.35416666667vw] sm:w-[3.125vw] w-[5.64102564103vw]"
                                        />
                                        <span className="text18 lg:leading-[1.66666666667vw] block">
                                            Unlimited Secure Sharing Links
                                        </span>
                                    </li>
                                    <li className="flex items-start lg:gap-x-[0.36458333333vw] sm:gap-x-[0.65104166666vw] gap-x-[2.05128205128vw]">
                                        <img
                                            src="./assets/images/icons/check.svg"
                                            alt="check"
                                            className="flipped lg:w-[1.35416666667vw] sm:w-[3.125vw] w-[5.64102564103vw]"
                                        />
                                        <span className="text18 lg:leading-[1.66666666667vw] block">
                                            Add-ons enabled
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <HomePageButton setPlanState={setPlanState} PlanState={PlanState} isPricing={true} toggle={toggle} plan={8} classCustom="lg:mt-[2.1875vw] arrowHover startBtn sm:mt-[5vw] mt-[8vw] bg-[#992861]  lg:py-[0.78125vw] lg:px-[1.82291666667vw]"
                            />

                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default TabContent
