import axios from "./axiosConfig"; // Adjust the import path as needed
import toast from "react-hot-toast";

const ssoService = {
  login: async (app) => {
    try {
      const response = await axios.get("/api/sso/" + app);
      return response.data; // Assuming the API returns a token or user object
    } catch (error) {
      // console.log(error);
      if (error.response.data.error) {
        if (error.response.data.error.email) {
          toast.error(error.response.data.error.email);
        } else toast.error(error.response.data.error);
      } else toast.error(error.response.data.message);
      throw new Error(error.response.data.message);
    }
  },

  register: async (userData) => {
    try {
      const response = await axios.post("/api/register", userData);
      return response.data; // Assuming the API returns a success message or user object
    } catch (error) {
      // alert()
      if (error.response.data.error.errors.email) {
        toast.error(error.response.data.error.errors.email);
      } else if (error.response.data.error.errors.password) {
        toast.error(error.response.data.error.errors.password);
      } else toast.error(error.response.data.error.message);

      throw new Error(error.response.data);
    }
  },

  logout: async () => {
    try {
      await axios.post("/api/sso/logout");
      // Optionally clear token or user data from localStorage or state
    } catch (error) {
      // console.log(error);

      toast.error(error.response.data.message);
      throw new Error(error.response.data.message);
    }
  },

  // Other methods like refreshToken, isAuthenticated, etc. can be added here
};

export default ssoService;
