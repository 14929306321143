import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import NavigateLoader from "./Navbar/Modals/Login/NavigateLoader";
import { useNavigate } from "react-router-dom";

function SSOLOGIN() {
  // get token from url
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get("token");
  const redirect = urlParams.get("redirect");
  const path = urlParams.get("path");
  const url = urlParams.get("redirectPage");

  // save token to local storage

  // redirect to dashboard
  // const nav = useNavigate();ك

  // alert(token)
  if (token) {
    // alert(token)
    localStorage.setItem("auth", JSON.stringify({ token: token }));
    if (redirect == "true") {
      if (url) window.location.href = url;
      else window.location.href = "/" + path;
    }
  } else {
    // clear local storage
    localStorage.removeItem("auth");
  }

  return (
    <>
      <NavigateLoader />
    </>
  );
}

export default SSOLOGIN;
