import React, { useEffect, useState } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AOS from "aos";
import { useRef } from 'react';
import SupportSection from '../../Components/SupportSection/SupportSection.jsx';
import Footer from '../../Components/Footer/Footer.jsx';
import { Helmet } from 'react-helmet';

function FAQs() {

    const [expanded, setExpanded] = useState(false); // State to manage which accordion is open

    useEffect(() => {
        AOS.init({
            duration: 800,
            easing: 'ease',
            once: 'true',
            mirror: true,
        });
    }, []);

    const summaryRefs = useRef(new Array(13).fill().map(() => React.createRef())); // Initialize refs for summaries

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);

        setTimeout(() => { // Wait for accordion transitions to finish
            if (isExpanded) {
                const prevPanelIndex = panel === 0 ? summaryRefs.current.length - 1 : panel - 1;
                const prevSummary = summaryRefs.current[prevPanelIndex]?.current;

                if (prevSummary) {
                    const summaryTop = prevSummary.getBoundingClientRect().top + window.pageYOffset;
                    window.scrollTo({
                        top: summaryTop,
                        behavior: 'smooth'
                    });
                }
            }
        }, 300);  // Adjust delay if necessary to account for the time it takes for the accordion to animate open/close.
    };



    return (
        <div>
            <Helmet>
                <title>
                    GrandTransfer FAQ - Learn About Secure File Sharing & Data Protection |
                    GrandTransfer
                </title>
                <meta
                    name="title"
                    content="GrandTransfer FAQ - Learn About Secure File Sharing & Data Protection | GrandTransfer"
                />
                <meta
                    name="keywords"
                    content="file sharing FAQ, secure data transfer questions, file management help, data protection information, file sharing service queries, pricing FAQs, secure platform details, data security FAQs"
                />

                <meta
                    name="description"
                    content="Our FAQ page addresses your top questions about GrandTransfer's secure file sharing services. Get insights on file management, understand our pricing structure, and learn how we safeguard your data. We're here to help you make informed decisions about your file transfer needs."
                />
                <meta property="og:url" content="https://grandtransfer.io/faqs" />
                <meta property="og:type" content="website" />
                <meta
                    property="og:title"
                    content="GrandTransfer FAQ - Learn About Secure File Sharing & Data Protection | GrandTransfer"
                />
                <meta
                    property="og:description"
                    content="Our FAQ page addresses your top questions about GrandTransfer's secure file sharing services. Get insights on file management, understand our pricing structure, and learn how we safeguard your data. We're here to help you make informed decisions about your file transfer needs."
                />
                <meta
                    property="og:image"
                    content="https://www.grandtransfer.io/assets/images/ogImage.jpg"
                />
                <meta property="og:image:type" content="image/jpg" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta
                    property="og:image:alt"
                    content="Our FAQ page addresses your top questions about GrandTransfer's secure file sharing services. Get insights on file management, understand our pricing structure, and learn how we safeguard your data. We're here to help you make informed decisions about your file transfer needs."
                />
                <meta
                    name="google-site-verification"
                    content="mHLXZqgcUz-ADXgzZ39KarFwbd3KiR1HFyICTESENFs"
                />
                <meta
                    property="twitter:title"
                    content="GrandTransfer FAQ - Learn About Secure File Sharing & Data Protection | GrandTransfer"
                />
                <meta
                    property="twitter:description"
                    content="Our FAQ page addresses your top questions about GrandTransfer's secure file sharing services. Get insights on file management, understand our pricing structure, and learn how we safeguard your data. We're here to help you make informed decisions about your file transfer needs."
                />
                <meta
                    property="twitter:image"
                    content="https://www.grandtransfer.io/assets/images/ogImage.jpg"
                />
                <meta property="twitter:image:width" content="1200" />
                <meta property="twitter:image:height" content="630" />
                <meta
                    property="twitter:image:alt"
                    content="Our FAQ page addresses your top questions about GrandTransfer's secure file sharing services. Get insights on file management, understand our pricing structure, and learn how we safeguard your data. We're here to help you make informed decisions about your file transfer needs."
                />

                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/assets/images/favicons/apple-touch-icon.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/assets/images/favicons/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/assets/images/favicons/favicon-16x16.png"
                />
                <link rel="manifest" href="/assets/images/favicons/site.webmanifest" />
                <link
                    rel="mask-icon"
                    href="/assets/images/favicons/safari-pinned-tab.svg"
                    color="#992861"
                />
                <link rel="shortcut icon" href="/assets/images/favicons/favicon.ico" />
                <meta name="msapplication-TileColor" content="#992861" />
                <meta
                    name="msapplication-config"
                    content="/assets/images/favicons/browserconfig.xml"
                />
                <meta name="theme-color" content="#ffffff" />


            </Helmet>
            <section className="bg-black relative FAQheader">
                <div className="">
                    <div className="text-white relative lg:w-[41.5625vw] pt-[30vw] pb-[12vw] sm:pt-[24vw] sm:pb-[10vw] lg:pb-[6.09375vw] lg:pt-[12.2395833333vw]">
                        <h1 data-aos="fade-up">
                            FAQs
                        </h1>
                        <p data-aos="fade-down" className="text22 lg:mt-[1.04166666667vw] mt1">
                            Gain access to all information you require and effortlessly make
                            well-informed choices as we have addressed all your file sharing
                            inquiries right here.
                        </p>
                    </div>
                    <div className="hidden lg:block absolute z-[1] right-0 bottom-0">
                        <img
                            className="h-[92px] lg:h-[23vw]"
                            src="/assets/images/home/faq.png"
                            alt="FAQs"
                        />
                    </div>
                </div>
            </section>
            <section className='accordionSection'>
                <Accordion className='accordionText' onChange={handleChange(0)} expanded={expanded === 0}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='accordionIcon mt-2' />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className='fix'
                        ref={summaryRefs.current[0]}
                    >
                        How does the setup process work and what can I expect after joining GrandTransfer?
                    </AccordionSummary>
                    <AccordionDetails className='accordionDetails'>
                        <div>
                            Getting started with GrandTransfer is straightforward and user-friendly. Upon signing up, you'll immediately gain access to our cloud-based platform with no need for downloads or installations. Your free 12-month trial begins right away, offering a selection of features that showcase the convenience and capabilities of GrandTransfer.
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion className='accordionText' onChange={handleChange(1)} expanded={expanded === 1}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='accordionIcon' />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                        className='fix'
                        ref={summaryRefs.current[1]}
                    >
                        What benefits will I gain by upgrading to a paid plan with GrandTransfer?
                    </AccordionSummary>
                    <AccordionDetails className='accordionDetails'>
                        By upgrading to a GrandTransfer paid plan, you’ll experience the enhancements in capacity and capabilities. Enjoy expanded storage and transfer limits, allowing you to store larger files and share more data. Gain the ability to add more team members and create multiple workspaces, facilitating better collaboration. With our tiered paid plans, you can progressively increase these limits, and with our top-tier Max plan, you’ll unlock the potential for unlimited features. Every paid plan is designed to grow with your needs, providing the scalability essential for your business or personal use.
                    </AccordionDetails>
                </Accordion>

                <Accordion className='accordionText' onChange={handleChange(2)} expanded={expanded === 2}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='accordionIcon' />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                        className='fix'
                        ref={summaryRefs.current[2]}

                    >
                        What are Add-ons, and how can I use them with GrandTransfer?
                    </AccordionSummary>
                    <AccordionDetails className='accordionDetails'>
                        Add-ons in GrandTransfer are a flexible way to purchase additional GB for storage or transfer as per your specific needs. These are perfect for when you require more capacity beyond your current plan limits. However, Add-ons are an exclusive feature available only with our top-tier Max plan. This allows Max plan users the unique advantage of customizing their capacity to any extent required, ensuring that your storage and transfer capabilities can scale seamlessly with your demands. Whether it's a sudden spike in data needs or a project requiring substantial space, Add-ons provide the perfect solution for maximizing your resources on the fly
                    </AccordionDetails>
                </Accordion>

                <Accordion className='accordionText' onChange={handleChange(3)} expanded={expanded === 3}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='accordionIcon' />}
                        aria-controls="panel4-content"
                        id="panel4-header"
                        className='fix'
                        ref={summaryRefs.current[3]}
                    >
                        Do my recipients need to sign up to download files from GrandTransfer?
                    </AccordionSummary>
                    <AccordionDetails className='accordionDetails'>
                        No, recipients do not need to sign up or log in to download files shared via GrandTransfer. We've designed our platform for ease of use, ensuring that anyone you share files with can access them without the need for an account, making the process quick and hassle-free."
                    </AccordionDetails>
                </Accordion>

                <Accordion className='accordionText' onChange={handleChange(4)} expanded={expanded === 4}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='accordionIcon' />}
                        aria-controls="panel5-content"
                        id="panel5-header"
                        className='fix'
                        ref={summaryRefs.current[4]}
                    >
                        How does "AI Facial Recognition" work in file sharing?
                    </AccordionSummary>
                    <AccordionDetails className='accordionDetails'>
                        Our "AI Facial Recognition" feature enhances the security of file sharing. When you enable it, recipients are prompted to take a photo of their face. Our AI doesn't identify specific individuals but simply verifies the presence of a human face before granting access to the shared file. This ensures an additional layer of security, confirming that a real person is attempting to view the content.
                    </AccordionDetails>
                </Accordion>

                <Accordion className='accordionText' onChange={handleChange(5)} expanded={expanded === 5}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='accordionIcon' />}
                        aria-controls="panel6-content"
                        id="panel6-header"
                        className='fix'
                        ref={summaryRefs.current[5]}
                    >
                        How does the 'Predefined Receivers' feature work in GrandTransfer?
                    </AccordionSummary>
                    <AccordionDetails className='accordionDetails'>
                        The 'Predefined Receivers' feature in GrandTransfer enhances security and convenience in file sharing. As a user, you can add your recipients as 'Predefined Receivers.' They will receive an invitation to join GrandTransfer and register their face using our AI Facial Recognition technology. Once they complete this one-time setup, our system authenticates their identity for future file transfers. This means whenever you share a file with a 'Predefined Receiver,' they will need to verify their identity through facial recognition to access the file. This added layer of security ensures that only the intended recipient can open and view your shared files, providing peace of mind and enhanced data protection"
                    </AccordionDetails>
                </Accordion>

                <Accordion className='accordionText' onChange={handleChange(6)} expanded={expanded === 6}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='accordionIcon' />}
                        aria-controls="panel7-content"
                        id="panel7-header"
                        className='fix'
                        ref={summaryRefs.current[6]}
                    >
                        How do 'workspaces' work in GrandTransfer, and what should I know about inviting team members?
                    </AccordionSummary>
                    <AccordionDetails className='accordionDetails'>
                        In GrandTransfer, 'workspaces' are collaborative spaces where you and your team can manage files together. When you register with GrandTransfer, a default workspace is automatically created for you. Within this workspace, you can invite other team members. These members will receive an invitation to register and join your workspace.
                        It's important to note that the number of workspaces and team members you can have is determined by your subscription plan’s quotas. Storage, transfer limits, and other features are shared across the workspace. This means that any file uploaded by a team member counts towards the total storage quota of the workspace owner’s account. Similarly, any file shared by a team member will count towards the workspace owner’s transfer limit.
                    </AccordionDetails>
                </Accordion>

                <Accordion className='accordionText' onChange={handleChange(7)} expanded={expanded === 7}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='accordionIcon' />}
                        aria-controls="panel8-content"
                        id="panel8-header"
                        className='fix'
                        ref={summaryRefs.current[7]}
                    >
                        What should I do if I exceed my storage limit on GrandTransfer?
                    </AccordionSummary>
                    <AccordionDetails className='accordionDetails'>
                        If you reach your storage limit on GrandTransfer, there are a couple of options to manage your space. Firstly, you can free up storage by permanently deleting files you no longer need. Keep in mind that moving files to the trash does not count against your storage limit, but these files and folders in the trash will be automatically purged every 60 days. Alternatively, you can choose to upgrade your plan to increase your storage capacity. Until you either free up space or upgrade, you won't be able to upload additional files. This ensures you maintain control over your storage usage and continue to enjoy our file sharing services without interruption.
                    </AccordionDetails>
                </Accordion>

                <Accordion className='accordionText' onChange={handleChange(8)} expanded={expanded === 8}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='accordionIcon' />}
                        aria-controls="panel9-content"
                        id="panel9-header"
                        className='fix'
                        ref={summaryRefs.current[8]}
                    >
                        What occurs if I exceed my monthly transfer limit on GrandTransfer?
                    </AccordionSummary>
                    <AccordionDetails className='accordionDetails'>
                        At GrandTransfer, transfer limits are based on a monthly cycle. If you reach your transfer limit within a month, any files you attempt to share afterwards or has been shared won't be accessible to your recipients until the limit resets at the start of the next month. To avoid this interruption, you have two options: either wait for the monthly reset, which will reinstate your full transfer capacity, or consider upgrading to a higher plan that offers a larger transfer limit. Upgrading your plan is a great way to immediately increase your transfer capacity, ensuring continuous access for your recipients and uninterrupted service for your file sharing needs.
                    </AccordionDetails>
                </Accordion>

                <Accordion className='accordionText' onChange={handleChange(9)} expanded={expanded === 9}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='accordionIcon' />}
                        aria-controls="panel10-content"
                        id="panel10-header"
                        className='fix'
                        ref={summaryRefs.current[9]}
                    >
                        What file types can I upload and share on GrandTransfer, and is there a limit to file size?
                    </AccordionSummary>
                    <AccordionDetails className='accordionDetails'>
                        On GrandTransfer, we support the upload and sharing of all file types, offering you complete flexibility in managing and distributing your data. Additionally, if you're sharing PDF files, you can take advantage of our convenient PDF viewer, which provides enhanced viewing features. We're also excited to announce that we will soon introduce viewers for other file types, further enhancing your file sharing experience.
                        In terms of file size, you can upload files of up to 5GB. This generous limit is designed to accommodate a wide range of needs, from large video files to extensive data sets. Whether you're sharing standard documents or large multimedia files, GrandTransfer is equipped to handle your requirements with ease.
                    </AccordionDetails>
                </Accordion>

                <Accordion className='accordionText' onChange={handleChange(10)} expanded={expanded === 10}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='accordionIcon' />}
                        aria-controls="panel11-content"
                        id="panel11-header"
                        className='fix'
                        ref={summaryRefs.current[10]}
                    >
                        How can I raise a support ticket if I need help with GrandTransfer?
                    </AccordionSummary>
                    <AccordionDetails className='accordionDetails'>
                        If you require assistance or have any inquiries about our service, you can easily raise a support ticket through our dedicated platform at https://support.grandautomation.io/. Here, you can submit detailed information about your issue or question, and our dedicated support team will get back to you promptly. We're committed to providing timely and helpful responses to ensure your experience with GrandTransfer is smooth and hassle-free. Whether it’s a technical question, account issue, or general query, our support system is designed to address your needs efficiently.
                    </AccordionDetails>
                </Accordion>

                <Accordion className='accordionText' onChange={handleChange(11)} expanded={expanded === 11}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='accordionIcon' />}
                        aria-controls="panel12-content"
                        id="panel12-header"
                        className='fix'
                        ref={summaryRefs.current[11]}
                    >
                        How does canceling or downgrading my GrandTransfer subscription work?
                    </AccordionSummary>
                    <AccordionDetails className='accordionDetails'>
                        If you choose to cancel or downgrade your GrandTransfer subscription, the change will take effect at the start of your next billing cycle, whether it's monthly or yearly. This means that until your current billing period ends, you will continue to enjoy the full benefits of your current plan. Once the cancellation or downgrade is processed in the next cycle, your account will reflect the new plan status. Please note that with a downgrade or cancellation, there will be adjustments to your feature quotas, including storage capacity, transfer limits, and the number of watermarks available to you, among others. It's important to plan accordingly to ensure that these changes align with your ongoing file sharing and storage needs.
                    </AccordionDetails>
                </Accordion>
            </section>
            <SupportSection />
            <Footer />
        </div>
    )
}

export default FAQs