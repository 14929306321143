



import React, { useState, useEffect, useContext } from 'react'
import { toggleSidebar } from './toggle.js';
import MenuIcon from '../../Icons/menuIcon.svg'
import logo from '../../images/Logos02.png'
import logoSidebar from '../../images/Logo01.png'
import { Link, useNavigate } from 'react-router-dom';
import ProfileIcon from '../../Icons/logout icon.svg'
import SidePanelIcon from '../../Icons/icon-park-outline_application-menu.svg'
import SupportIcon from '../../Icons/radix-icons_external-link.svg'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import 'animate.css';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

// Import products images
import TransferProduct from '../../images/ProductsLogo/whiteBG/TransferWhiteBg.svg';
import Calender from '../../images/ProductsLogo/whiteBG/calenderWhiteBg.svg';
import Form from '../../images/ProductsLogo/whiteBG/Property 1=G-Form.svg';
import WorkFlow from '../../images/ProductsLogo/whiteBG/Property 1=G-Workflow.svg';
import SupportTick from '../../images/ProductsLogo/whiteBG/Property 1=G-Support Tick.svg';
import HRM from '../../images/ProductsLogo/whiteBG/Property 1=G-HRM.svg';
import Signature from '../../images/ProductsLogo/whiteBG/Property 1=G-Signature.svg';
import Accounting from '../../images/ProductsLogo/whiteBG/Property 1=G-Accounting.svg';
import Projects from '../../images/ProductsLogo/whiteBG/Property 1=G-Projects.svg';
import Forms from '../../images/ProductsLogo/whiteBG/Property 1=G-Forms.svg';
import Inventory from '../../images/ProductsLogo/whiteBG/Property 1=G-Inventory.svg';
import VideoConference from '../../images/ProductsLogo/whiteBG/Property 1=G-Video Conference.svg';

// import icons
import settingsIcon from '../../Icons/settings icon.svg';
import logoutIcon from '../../Icons/logout icon.svg';



import AOS from "aos";
import { createPopper } from '@popperjs/core';
import 'aos/dist/aos.css';
import HomePageButton from '../../Components/HomePageButton.js';
import SupportSection from '../../Components/SupportSection/SupportSection.jsx';
import Footer from '../../Components/Footer/Footer.jsx';
import { Helmet } from 'react-helmet';


function LandingPage({ open2, toggle }) {

  // products logos
  const products = [
    TransferProduct,
    Calender,
    Form,
    WorkFlow,
    SupportTick,
    HRM,
    Signature,
    Accounting,
    Projects,
    Forms,
    Inventory,
    VideoConference
  ];

  // offcanvas
  const [open, setOpen] = useState(false);

  // dropDown Menu
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDropdown = Boolean(anchorEl);
  const id = openDropdown ? 'simple-popover' : undefined;



  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: 'ease',
      once: 'true', // whether animation should happen only once - while scrolling down
      mirror: true,
    });
  }, []);





  return (
    <div>
      <Helmet>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, minimum-scale=1, maximum-scale=1"
        />
        <link rel="stylesheet" href="./assets/styles/aos.css" />
        <link rel="stylesheet" href="./assets/styles/output.css" />
        <title>
          Reliable and Secure File Sharing – Send and Share All File Types Safely
          Online | GrandTransfer
        </title>
        <meta
          name="title"
          content="Reliable and Secure File Sharing – Send and Share All File Types Safely Online | GrandTransfer"
        />
        <meta
          name="keywords"
          content="secure file sharing, confidential file transfer, protected file storage, PDF, Docs, Images, Videos"
        />

        <meta
          name="description"
          content="Securely transfer, store, and send confidential files with GrandTransfer. Advanced features ensure the utmost security for your shared files"
        />
        <meta property="og:url" content="https://grandtransfer.io" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Reliable and Secure File Sharing – Send and Share All File Types Safely Online | GrandTransfer"
        />
        <meta
          property="og:description"
          content="Securely transfer, store, and send confidential files with GrandTransfer. Advanced features ensure the utmost privacy for your shared files"
        />
        <meta
          property="og:image"
          content="https://www.grandtransfer.io/assets/images/ogImage.jpg"
        />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta
          property="og:image:alt"
          content="Securely transfer, store, and send confidential files with GrandTransfer. Advanced features ensure the utmost privacy for your shared files"
        />
        <meta
          name="google-site-verification"
          content="mHLXZqgcUz-ADXgzZ39KarFwbd3KiR1HFyICTESENFs"
        />
        <meta
          property="twitter:title"
          content="Reliable and Secure File Sharing – Send and Share All File Types Safely Online | GrandTransfer"
        />
        <meta
          property="twitter:description"
          content="Securely transfer, store, and send confidential files with GrandTransfer. Advanced features ensure the utmost privacy for your shared files"
        />
        <meta
          property="twitter:image"
          content="https://www.grandtransfer.io/assets/images/ogImage.jpg"
        />
        <meta property="twitter:image:width" content="1200" />
        <meta property="twitter:image:height" content="630" />
        <meta
          property="twitter:image:alt"
          content="Securely transfer, store, and send confidential files with GrandTransfer. Advanced features ensure the utmost privacy for your shared files"
        />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/assets/images/favicons/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/assets/images/favicons/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/assets/images/favicons/favicon-16x16.png"
        />
        <link rel="manifest" href="/assets/images/favicons/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/assets/images/favicons/safari-pinned-tab.svg"
          color="#992861"
        />
        <link rel="shortcut icon" href="/assets/images/favicons/favicon.ico" />
        <meta name="msapplication-TileColor" content="#992861" />
        <meta
          name="msapplication-config"
          content="/assets/images/favicons/browserconfig.xml"
        />
        <meta name="theme-color" content="#ffffff" />

      </Helmet>
      {/* dropDown Menu  */}
      <div>
        <Popover
          id={id}
          open={openDropdown}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{ marginTop: 1 }}
        >
          <Typography>
            <div className=' text-sm p-4'>
              <Link to='/Account-Setting' className='flex pe-6 mb-5' onClick={handleClose}>
                <img src={settingsIcon} alt="" className='me-3' />
                <h6>
                  Account Settings
                </h6>
              </Link>
              <div className='flex cursor-pointer' onClick={() => { handleClose() }}>
                <img src={logoutIcon} alt="" className='me-3' />
                <h6>
                  Logout
                </h6>
              </div>
            </div>
          </Typography>
        </Popover>
      </div>

      {/* offcanvas */}
      <Transition.Root show={open} as={Fragment} >
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className={open ? "absolute inset-0 animate-opacityAnimation-start overflow-hidden" : "absolute inset-0 overflow-hidden"}>
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto offcanvansWidth">
                    <div className="flex h-full flex-col overflow-auto bg-white py-7 shadow-xl rounded-l-3xl">
                      <div className="relative flex-1 px-10 flex flex-col justify-between">

                        <div>
                          <div className='flex justify-center'>
                            <img src={logoSidebar} alt="" width={90} />
                          </div>

                          <div className='grid grid-cols-2 my-7'>
                            {products.map((product, index) => {
                              return <img key={index} src={product}
                                className={(index === 0) ? 'cursor-pointer' : ''} />
                            })}
                          </div>
                        </div>

                        <div>
                          <p className='mb-4 font-[350]'>We try to provide a unique experience to meet your business needs. So feel free to contact us to hear suggestions</p>
                          <button className='bg-black text-white font-semibold w-full text-center rounded-md py-2'>Contact Us</button>
                        </div>

                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <main>
        <section>
          <div
            class="w-full relative paddingTop lg:pb-[3.8vw] lg:px-[9.79166666667vw] sm:px-[6%] px-[5%] responsiveSpace"
          >
            <img
              src="./assets/images/home/hero2.jpg"
              alt="Share confidential files safely"
              class="absolute top-0 left-0 w-full h-full object-cover flipped tilt-container"
            />
            <div
              class="relative heroBox bg-[#FFF] lg:w-[32.8645833333vw] border12 lg:py-[3.125vw] sm:py-[6vw] py-[10vw]"
            >
              <div
                class="mx-[auto] sm:w-[90%] lg:w-[81%] w-[81%] flex flex-col items-start"
              >
                <div id="tile" class="text-[#18181B]">
                  <h1 data-aos="fade-up" class="text60">
                    Share confidential files safely
                  </h1>
                  <p data-aos="fade-down" class="text22 lg:mt-[1.5625vw] mt1">
                    Securely transfer, store, and share files while preventing
                    unauthorized sharing or viewing of your confidential data.
                  </p>
                </div>
                <HomePageButton toggle={toggle} />
              </div>
            </div>
          </div>
        </section>
        <section
          class="sm:pt-[10.625vw] sm:pt-[10.4166666667vw] pt-[15.3846153846vw]"
        >
          <div
            class="w- [90vw] w-[full] bg-white sm:w- [82.1354166667vw] px-[5vw] sm:px-[8.9322916667vw] mx-[auto] mb-[24px] sm:mb-[3.64583333333vw]"
          >
            <h2
              data-aos-anchor="tile"
              class="text60 text-[#18181B]"
              data-aos="fade-up"
            >
              Explore our powerful features
            </h2>
          </div>
          <div class="flex flex-col gap-y-[10vw]">
            <div
              class="w-[90vw] sm:w-[82.1354166667vw] mx-[auto] flex flex-col-reverse lg:flex-row gap-y-[30px]"
            >
              <div
                class="w-full h-[480px] sm:h-[75vw] lg:h-[unset] lg:w-[35.2604166667vw] relative z-[2]"
              >
                <div
                  data-aos="fade"
                  data-aos-delay="600"
                  class="featuresCard w-[200px] sm:w-[35vw] lg:w-[17.5520833333vw] top-0 lg:top-[2.34375vw] left-[10px] sm:left-[18vw] lg:left-[6.97916666667vw] absolute"
                >
                  <img
                    class="w-full"
                    src="./assets/images/home/pasSS.svg"
                    alt="password screenshot"
                  />
                </div>
                <div
                  data-aos="fade"
                  data-aos-delay="800"
                  class="featuresCard w-[80%] sm:w-[65vw] left-[6vw] lg:w-[29.6875vw] bottom-[60px] sm:bottom-[6.71875vw] lg:left-[2.55208333333vw] absolute"
                >
                  <img
                    class="w-full"
                    src="./assets/images/home/fileSS.svg"
                    alt="files screenshot"
                  />
                </div>
                <div
                  data-aos="fade"
                  data-aos-delay="400"
                  class="featuresCard w-[150px] sm:w-[28vw] right-[2vw] lg:w-[12.7604166667vw] top-[100px] sm:top-[4.42708333333vw] lg:left-[24.0104166667vw] absolute"
                >
                  <img
                    class="w-full"
                    src="./assets/images/home/deviceSS.svg"
                    alt="device screenshot"
                  />
                </div>
              </div>
              <div
                class="lg:w-[46.875vw] relative pt-[220px] sm:pt-[40vw] lg:pt-0 lg:h-[43.0729166667vw] flex items-end rounded-[12px] lg:rounded-[0.625vw] bg-black"
              >
                <div
                  class="w-full h-[68%] sm:h-full absolute top-[0] sm:top-[0] lg:top-[0] left-0"
                >
                  <img
                    src="./assets/images/home/first.png"
                    alt="frame"
                    class="w-full h-full rounded-[15px] object-cover"
                  />
                </div>
                <div class="p-[24px] sm:p-[30px] lg:p-[3.64583333333vw] w-full">
                  <div
                    class="sm:max-w-[66vw] lg:max-w-[31.0416666667vw] p-6 lg:p-[1.667vw] redial-grad"
                  >
                    <h5
                      data-aos="fade-up"
                      class="text50 text-white mb-[12px] sm:mb-[1vw]"
                    >
                      Secure and private file sharing
                    </h5>
                    <p data-aos="fade-down" class="text22 text-[#F4F5F7]">
                      With features such as advanced encryption, password
                      protection, screenshot prevention, and document expiry,
                      ensure your confidential documents are securely transferred
                      and shared the way you need them to be.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="w-[90vw] sm:w-[82.1354166667vw] mx-[auto] flex flex-col lg:flex-row sm:gap-y-[30px]"
            >
              <div
                class="lg:w-[46.875vw] relative pb-[220px] sm:pb-[40vw] lg:pb-0 lg:h-[43.0729166667vw] flex items-start rounded-[12px] lg:rounded-[0.625vw] bg-black"
              >
                <div
                  class="w-full h-[68%] sm:h-full absolute bottom-[0] sm:bottom-[0] lg:bottom-[0] left-0"
                >
                  <img
                    src="./assets/images/home/second.png"
                    alt="frame"
                    class="w-full h-full rounded-[15px] object-cover"
                  />
                </div>
                <div class="p-[24px] sm:p-[30px] lg:p-[3.64583333333vw] w-full">
                  <div
                    class="sm:max-w-[66vw] lg:max-w-[31.0416666667vw] p-6 lg:p-[1.667vw] redial-grad"
                  >
                    <h5
                      data-aos="fade-up"
                      class="text-white text50 mb-[12px] sm:mb-[1vw]"
                    >
                      Facial recognition
                    </h5>
                    <p data-aos="fade-down" class="text22 text-[#F4F5F7]">
                      When password protection and watermarking are not enough of
                      a deterence, take the security of your transfer, send, and
                      documents to the next level with facial recognition. Obtain
                      proof of delivery and enhanced security by enabling file
                      access via registered faces, or alternatively, enable file
                      access only after a face has been recognized and captured.
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="w-full h-[300px] sm:h-[63vw] lg:h-[unset] lg:w-[35.2604166667vw] sm:mt-[-6.5vw] lg:mt-[0] relative z-[2]"
              >
                <div
                  data-aos="fade"
                  data-aos-delay="600"
                  class="w-[300px] sm:w-[50vw] right-[8vw] bottom-[9vw] sm:top-[15vw] lg:w-[24.1875vw] lg:h-[24.1875vw] absolute lg:top-[7.8125vw] lg:right-0"
                >
                  <img
                    class="w-full"
                    src="./assets/images/home/frame.svg"
                    alt="password screenshot"
                  />
                  <img
                    src="./assets/images/home/profile.png"
                    alt=""
                    class="absolute top-[49%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] photo"
                  />
                </div>
                <div
                  class="w-[40vw] top-[9vw] left-0 lg:w-[20vw] absolute lg:left-[-3vw] lg:top-[5vw]"
                >
                  <img src="/assets/images/home/access.svg" class="w-full" />
                </div>
                <div
                  data-aos="fade"
                  data-aos-delay="800"
                  class="top-[2vw] right-[2vw] absolute w-[45vw] sm:w-[40vw] lg:w-[18vw]"
                >
                  <img
                    class="w-full"
                    src="./assets/images/home/facial.svg"
                    alt="files screenshot"
                  />
                </div>
                <div
                  data-aos="fade"
                  data-aos-delay="400"
                  class="w-[80px] sm:w-[20vw] left-[60px] sm:left-[21vw] bottom-[60px] sm:bottom-[24vw] lg:w-[10vw] absolute lg:bottom-[18vw] lg:left-[9vw]"
                >
                  <img
                    class="w-full"
                    src="./assets/images/home/capture.svg"
                    alt="device screenshot"
                  />
                </div>
              </div>
            </div>
            <div
              class="w-[90vw] sm:w-[82.1354166667vw] mx-[auto] flex flex-col-reverse lg:flex-row gap-y-[30px]"
            >
              <div
                class="w-full h-[300px] sm:h-[65vw] lg:h-[unset] lg:w-[35.2604166667vw] relative z-[2]"
              >
                <div
                  data-aos="fade"
                  data-aos-delay="400"
                  class="absolute top-[-6.2vw] sm:mt-[-10vw] lg:mt-0 sm:top-[5.8vw] left-[3vw] w-[55vw] sm:w-[50vw] lg:w-[25.875vw]"
                >
                  <img
                    class="w-full"
                    src="./assets/images/home/text.svg"
                    alt="device screenshot"
                  />
                </div>
                <div
                  data-aos="fade"
                  data-aos-delay="600"
                  class="featuresCard w-[42vw] sm:w-[35vw] lg:w-[16.71875vw] bottom-0 lg:bottom-[3.90625vw] right-[7vw] lg:right-[-5vw] absolute"
                >
                  <img
                    class="w-full"
                    src="./assets/images/home/watermark.svg"
                    alt="password screenshot"
                  />
                </div>
              </div>
              <div
                class="lg:w-[46.875vw] relative pt-[220px] sm:pt-[46vw] lg:pt-0 lg:h-[43.0729166667vw] flex items-end rounded-[12px] lg:rounded-[0.625vw] bg-black"
              >
                <div
                  class="w-full h-[68%] sm:h-full absolute top-[0] sm:top-[0] lg:top-[0] left-0"
                >
                  <img
                    src="./assets/images/home/third.png"
                    alt="frame"
                    class="w-full h-full rounded-[15px] object-cover"
                  />
                </div>
                <div class="p-[24px] sm:p-[30px] lg:p-[3.64583333333vw] w-full">
                  <div
                    class="sm:max-w-[66vw] lg:max-w-[31.0416666667vw] p-6 lg:p-[1.667vw] redial-grad lg:ml-[6.33333333333vw]"
                  >
                    <h5
                      data-aos="fade-up"
                      class="text50 mb-[12px] text-white sm:mb-[1vw]"
                    >
                      Automated watermarks
                    </h5>
                    <p data-aos="fade-down" class="text22 text-[#F4F5F7]">
                      Efficiently deter unauthorized document sharing and ensure
                      secure file sharing with watermarking templeting,
                      offering quick, customizable settings for each document and
                      recipient to save time and effort.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="w-[90vw] sm:w-[82.1354166667vw] mx-[auto] flex flex-col lg:flex-row gap-y-[15px]"
            >
              <div
                class="lg:w-[46.875vw] relative pb-[230px] sm:pb-[49vw] lg:pb-0 lg:h-[43.0729166667vw] flex items-start rounded-[12px] lg:rounded-[0.625vw] bg-black"
              >
                <div
                  class="w-full transform scale-x-[-1] h-[68%] sm:h-full absolute bottom-[0] sm:bottom-[0] lg:bottom-[0] left-0"
                >
                  <img
                    src="./assets/images/home/forth.png"
                    alt="frame"
                    class="w-full h-full rounded-[15px] object-cover"
                  />
                </div>
                <div class="p-[24px] sm:p-[30px] lg:p-[3.64583333333vw] w-full">
                  <div
                    class="sm:max-w-[66vw] lg:ml-[5.33333333333vw] lg:max-w-[31.0416666667vw] p-6 lg:p-[1.667vw] redial-grad"
                  >
                    <h5
                      data-aos="fade-up"
                      class="text50 mb-[12px] text-white sm:mb-[1vw]"
                    >
                      Share large files fast
                    </h5>
                    <p data-aos="fade-down" class="text22 text-[#F4F5F7]">
                      Quickly move, share, and transfer documents, photos, videos,
                      and other large files, at the touch of a button. With a
                      generous storage of 3TB and a maximum file size of 50GB, you
                      can seamlessly send and share even your largest files.
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="w-full h-[300px] sm:h-[63vw] lg:h-[unset] lg:w-[35.2604166667vw] relative z-[2]"
              >
                <div
                  data-aos="fade"
                  data-aos-delay="600"
                  class="featuresCard absolute bottom-0 lg:bottom-[7.34375vw] right-[18.5416666667vw] lg:w-[25.9375vw]"
                >
                  <img
                    class="w-full"
                    src="./assets/images/home/share.svg"
                    alt="password screenshot"
                  />
                </div>
                <div
                  data-aos="fade"
                  data-aos-delay="700"
                  class="absolute bottom-[12vw] lg:bottom-[9vw] right-[-4vw] lg:right-[6vw] w-[35vw] lg:w-[18vw]"
                >
                  <img src="/assets/images/home/share2.svg" class="w-full" />
                </div>
                <div
                  data-aos="fade"
                  data-aos-delay="800"
                  class="absolute w-[52vw] top-[2vw] lg:w-[33.125vw] lg:top-[5.10416666667vw] right-[1vw] featuresCard"
                >
                  <img
                    class="w-full"
                    src="./assets/images/home/share3.svg"
                    alt="files screenshot"
                  />
                </div>
              </div>
            </div>
            <div
              class="w-[90vw] sm:w-[82.1354166667vw] mx-[auto] flex flex-col-reverse lg:flex-row gap-y-[10px]"
            >
              <div
                class="w-full h-[480px] sm:h-[95vw] lg:h-[unset] lg:w-[35.2604166667vw] relative z-[2]"
              >
                <div
                  data-aos="fade"
                  data-aos-delay="600"
                  class="featuresCard w-[57%] left-[10%] sm:left-[18%] sm:left-[20vw] sm:w-[40vw] lg:w-[20.1041666667vw] absolute left-[20vw] lg:left-[3.07291666667vw] bottom-[1vw]"
                >
                  <img
                    class="w-full"
                    src="./assets/images/home/grained1.svg"
                    alt="password screenshot"
                  />
                </div>
                <div
                  data-aos="fade"
                  data-aos-delay="800"
                  class="w-[250px] right-[-21px] sm:right-0 lg:right-[2vw] bottom-[50px] sm:bottom-[9vw] lg:bottom-[5.5vw] sm:w-[40vw] lg:w-[18.375vw] absolute"
                >
                  <img
                    class="w-full"
                    src="./assets/images/home/grained2.svg"
                    alt="files screenshot"
                  />
                </div>
                <div
                  data-aos="fade"
                  data-aos-delay="400"
                  class="featuresCard w-[90%] sm:w-[70vw] left-[5vw] lg:w-[30.9895833333vw] top-[2.39583333333vw] lg:left-[9.01041666667vw] absolute"
                >
                  <img
                    class="w-full"
                    src="./assets/images/home/grained3.svg"
                    alt="device screenshot"
                  />
                </div>
              </div>
              <div
                class="lg:w-[46.875vw] relative pt-[220px] sm:pt-[52vw] lg:pt-0 lg:h-[43.0729166667vw] flex items-end rounded-[12px] lg:rounded-[0.625vw] bg-black"
              >
                <div
                  class="w-full h-[77%] sm:h-full absolute top-[0] sm:top-[0] lg:top-[0] left-0"
                >
                  <img
                    src="./assets/images/home/fifth.png"
                    alt="frame"
                    class="w-full h-full rounded-[15px] object-cover"
                  />
                </div>
                <div class="p-[24px] sm:p-[30px] lg:p-[3.64583333333vw] w-full">
                  <div
                    class="sm:max-w-[66vw] lg:max-w-[31.0416666667vw] p-6 lg:p-[1.667vw] redial-grad"
                  >
                    <h5
                      data-aos="fade-up"
                      class="text50 mb-[12px] text-white sm:mb-[1vw]"
                    >
                      Fine grained access control
                    </h5>
                    <p data-aos="fade-down" class="text22 text-[#F4F5F7]">
                      Easily control and transfer access to your files among team
                      members, devices, and geo-locations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="w-[90vw] sm:w-[82.1354166667vw] mx-[auto] flex flex-col lg:flex-row gap-y-[30px]"
            >
              <div
                class="lg:w-[46.875vw] relative pb-[220px] sm:pb-[45vw] lg:pb-0 lg:h-[43.0729166667vw] flex items-start rounded-[12px] lg:rounded-[0.625vw] bg-black"
              >
                <div
                  class="w-full h-[80%] sm:h-full absolute bottom-[0] sm:bottom-[0] lg:bottom-[0] left-0"
                >
                  <img
                    src="./assets/images/home/sixth.png"
                    alt="frame"
                    class="w-full h-full rounded-[15px] object-cover"
                  />
                </div>
                <div class="p-[24px] sm:p-[30px] lg:p-[3.64583333333vw] w-full">
                  <div
                    class="sm:max-w-[66vw] lg:max-w-[31.0416666667vw] p-6 lg:p-[1.667vw] redial-grad"
                  >
                    <h5
                      data-aos="fade-up"
                      class="text50 mb-[12px] text-white sm:mb-[1vw]"
                    >
                      Detailed analytics and tracking
                    </h5>
                    <p data-aos="fade-down" class="text22 text-[#F4F5F7]">
                      Keep track of who securely viewed your files, as well as the
                      location and number of secure views, prints, and downloads.
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="w-full h-[300px] sm:h-[63vw] lg:h-[unset] lg:w-[35.2604166667vw] relative z-[2]"
              >
                <div
                  data-aos="fade"
                  data-aos-delay="800"
                  class="featuresCard w-[70vw] lg:w-[31.4583333333vw] top-[13.3333333333vw] right-[8.33333333333vw] absolute"
                >
                  <img
                    class="w-full"
                    src="./assets/images/home/a5.svg"
                    alt="password screenshot"
                  />
                </div>
                <div
                  data-aos="fade"
                  data-aos-delay="700"
                  class="absolute w-[25vw] right-[-2vw] top-[6vw] lg:w-[15vw] lg:right-0 lg:top-[13vw]"
                >
                  <img src="/assets/images/home/analytics2.svg" class="w-full" />
                </div>
                <div
                  data-aos="fade"
                  data-aos-delay="600"
                  class="featuresCard absolute w-[60vw] lg:w-[33.6458333333vw] left-[-2vw] top-[1vw] lg:top-[6.5vw]"
                >
                  <img
                    class="w-full"
                    src="./assets/images/home/analytics3.svg"
                    alt="files screenshot"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <SupportSection />
      <Footer />

    </div>
  )

}

export default LandingPage
