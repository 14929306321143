import React, { useEffect, useContext, useState } from "react";
import "./style.css";
import Footer from "../Components/Footer/Footer";
import SupportSection from "../Components/SupportSection/SupportSection";
import AOS from "aos";
import { Helmet } from "react-helmet";

function NotFound() {
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease",
      once: "true", // whether animation should happen only once - while scrolling down
      mirror: true,
    });
  }, []);

  return (
    <div>
      <Helmet>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, minimum-scale=1, maximum-scale=1"
        />

        <link rel="stylesheet" href="./assets/styles/aos.css" />
        <link rel="stylesheet" href="./assets/styles/output.css" />
        <title>404 | GrandTransfer</title>
        <meta name="title" content="404 Page Not Found | GrandTransfer" />
        <meta name="keywords" content="404 Page Not Found | GrandTransfer" />

        <meta name="description" content="404 Page Not Found | GrandTransfer" />
        <meta property="og:url" content="https://grandtransfer.io/404" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="404 Page Not Found | GrandTransfer"
        />
        <meta
          property="og:description"
          content="404 Page Not Found | GrandTransfer"
        />
        <meta
          property="og:image"
          content="https://www.grandtransfer.io/assets/images/ogImage.jpg"
        />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta
          property="og:image:alt"
          content="404 Page Not Found | GrandTransfer"
        />
        <meta
          name="google-site-verification"
          content="mHLXZqgcUz-ADXgzZ39KarFwbd3KiR1HFyICTESENFs"
        />
        <meta
          property="twitter:title"
          content="404 Page Not Found | GrandTransfer"
        />
        <meta
          property="twitter:description"
          content="404 Page Not Found | GrandTransfer"
        />
        <meta
          property="twitter:image"
          content="https://www.grandtransfer.io/assets/images/ogImage.jpg"
        />
        <meta property="twitter:image:width" content="1200" />
        <meta property="twitter:image:height" content="630" />
        <meta
          property="twitter:image:alt"
          content="404 Page Not Found | GrandTransfer"
        />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/assets/images/favicons/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/assets/images/favicons/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/assets/images/favicons/favicon-16x16.png"
        />
        <link rel="manifest" href="/assets/images/favicons/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/assets/images/favicons/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <link rel="shortcut icon" href="/assets/images/favicons/favicon.ico" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta
          name="msapplication-config"
          content="/assets/images/favicons/browserconfig.xml"
        />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <section
        className=" page_404  mt-4 "
        style={{
          marginTop: "100px",
        }}
      >
        <div className="header404">
          <div className="four_zero_four_bg ">
            <div class="contant_box_404">
              <p
                style={{
                  color: "#666",
                  fontWeight: "400",
                  lineHeight: "1.5",
                  marginBottom: "5px",
                }}
              >
                Look like you're lost
              </p>

              <p
                className="not-avaible"
                style={{
                  color: "#666",
                  fontWeight: "400",
                  lineHeight: "1.5",
                  marginBottom: "20px",
                }}
              >
                the page you are looking for not avaible!
              </p>

              <a href="" class="link_404">
                Go to Home
              </a>
            </div>
          </div>
        </div>
        <div className="mt-4">
          {/* support section */}
          <SupportSection topPadding={false} />
          <Footer />
        </div>
      </section>
    </div>
  );
}

export default NotFound;
