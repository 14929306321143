module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  apiConfig: {
    API_URL: "https://api.grandtransfer.io",
    APP_STAGING_URL: "https://api.grandautomation.io/",
    APP_URL: "https://api.grandautomation.io/",
  },
  stripe: {
    STRIPE_PUBLIC_KEY:
      "pk_test_51NxmYyJb6V3kqIJjThKyEiarx3PmxXuYgGbvtPVvl4tGgVWVdoTqLfE5w0bqkddVPKksuLQuohgMqLhdh4THS65W00fPmnZWGl",
  },
  
  appData: [
    {
      name: "Transfer App",
      shortName: "Gt",
      url: "https://www.grandtransfer.io/", // Replace with actual URL
    },
    {
      name: "Calender App",
      shortName: "gc",
      url: "https://app-stage.grandcalendar.io", // Replace with actual URL
    },
    {
      name: "Form App",
      shortName: "gf",
      url: "https://form.example.com", // Replace with actual URL
    },
    {
      name: "WorkFlow App",
      shortName: "gw",
      url: "https://workflow.example.com", // Replace with actual URL
    },
    {
      name: "Support Tick App",
      shortName: "gs",
      url: "https://supporttick.example.com", // Replace with actual URL
    },
    {
      name: "HRM App",
      shortName: "gh",
      url: "https://hrm.example.com", // Replace with actual URL
    },
    {
      name: "Signature App",
      shortName: "gs",
      url: "https://signature.example.com", // Replace with actual URL
    },
    {
      name: "Accounting App",
      shortName: "ga",
      url: "https://accounting.example.com", // Replace with actual URL
    },
    {
      name: "Projects App",
      shortName: "gp",
      url: "https://projects.example.com", // Replace with actual URL
    },
    {
      name: "Forms App",
      shortName: "gf",
      url: "https://forms.example.com", // Replace with actual URL
    },
    {
      name: "Inventory App",
      shortName: "gi",
      url: "https://inventory.example.com", // Replace with actual URL
    },
    {
      name: "Video Conference App",
      shortName: "gv",
      url: "https://videoconference.example.com", // Replace with actual URL
    },

    // Add more app data as needed
  ],
  ga: {
    GA_URL: "https://api.grandautomation.io",
    GA_STAGING_URL: "https://api.grandautomation.io",
    GA_APP: "https://grandautomation.io",
    SUPPORT: "https://support.grandautomation.io",
  },

  // stripe: {
  //   STRIPE_PUBLIC_KEY:"pk_live_51NxmYyJb6V3kqIJjmOMTZhX8Uj7ecEUWbnNzbDo8tOA4allx9d91FwULvWiN0YM6nArC8cAUr0WXFHH8hwoyQYAW00fyWFFyuO"
  // }
};
