


import Modal from 'react-bootstrap/Modal';
import RegisterPage from './RegisterPage';

function RegisterModal({ open, toggle }) {


    return (
        <Modal
            show={open}
            onHide={toggle}
            aria-labelledby="example-modal-sizes-title-sm"
            fullscreen={true}
            className=''
        >
            <Modal.Header closeButton className='loginForm title' closeVariant='white'>
                <Modal.Title id="" className=''>
                    <div style={{ marginRight: "2rem" }}>
                        <div style={{ fontSize: "1rem", fontWeight: "600", marginBottom: "0.1rem", color: 'white' }}>REGISTER</div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='loginForm flex items-center justify-center w-full'>
                {/* <LoginPage submit={submit} loading={loading} /> */}
                <RegisterPage />
            </Modal.Body>
        </Modal>
    )
}

export default RegisterModal
