



import React, { useEffect } from 'react'
import AOS from "aos";
import SupportSection from '../../Components/SupportSection/SupportSection.jsx';
import Footer from '../../Components/Footer/Footer.jsx';
import { Helmet } from 'react-helmet';

function PrivacyPolicy() {

  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: 'ease',
      once: 'true', // whether animation should happen only once - while scrolling down
      mirror: true,
    });
  }, []);

  return (
    <div>
      <Helmet>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, minimum-scale=1, maximum-scale=1"
        />

        <link rel="stylesheet" href="./assets/styles/aos.css" />
        <link rel="stylesheet" href="./assets/styles/output.css" />
        <title>Privacy Policy - Data Protection & Privacy | | GrandTransfer</title>
        <meta
          name="title"
          content="Privacy Policy - Data Protection & Privacy | | GrandTransfer"
        />
        <meta
          name="keywords"
          content="GrandTransfer privacy, data protection policy, user privacy details, information security policy, privacy guidelines, personal data usage, confidentiality policy, online privacy agreement"
        />

        <meta
          name="description"
          content="Read GrandTransfer's Privacy Policy to learn how we protect your personal data and privacy. Our commitment to data security is reflected in our transparent privacy practices and measures."
        />
        <meta property="og:url" content="https://grandtransfer.io/privacy-policy" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Privacy Policy - Data Protection & Privacy | | GrandTransfer"
        />
        <meta
          property="og:description"
          content="Read GrandTransfer's Privacy Policy to learn how we protect your personal data and privacy. Our commitment to data security is reflected in our transparent privacy practices and measures."
        />
        <meta
          property="og:image"
          content="https://www.grandtransfer.io/assets/images/ogImage.jpg"
        />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta
          property="og:image:alt"
          content="Read GrandTransfer's Privacy Policy to learn how we protect your personal data and privacy. Our commitment to data security is reflected in our transparent privacy practices and measures."
        />
        <meta
          name="google-site-verification"
          content="mHLXZqgcUz-ADXgzZ39KarFwbd3KiR1HFyICTESENFs"
        />
        <meta
          property="twitter:title"
          content="Privacy Policy - Data Protection & Privacy | | GrandTransfer"
        />
        <meta
          property="twitter:description"
          content="Read GrandTransfer's Privacy Policy to learn how we protect your personal data and privacy. Our commitment to data security is reflected in our transparent privacy practices and measures."
        />
        <meta
          property="twitter:image"
          content="https://www.grandtransfer.io/assets/images/ogImage.jpg"
        />
        <meta property="twitter:image:width" content="1200" />
        <meta property="twitter:image:height" content="630" />
        <meta
          property="twitter:image:alt"
          content="Read GrandTransfer's Privacy Policy to learn how we protect your personal data and privacy. Our commitment to data security is reflected in our transparent privacy practices and measures."
        />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/assets/images/favicons/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/assets/images/favicons/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/assets/images/favicons/favicon-16x16.png"
        />
        <link rel="manifest" href="/assets/images/favicons/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/assets/images/favicons/safari-pinned-tab.svg"
          color="#992861"
        />
        <link rel="shortcut icon" href="/assets/images/favicons/favicon.ico" />
        <meta name="msapplication-TileColor" content="#992861" />
        <meta
          name="msapplication-config"
          content="/assets/images/favicons/browserconfig.xml"
        />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <section className="bg-black relative">
        <div className="container1720">
          <div className="text-[#FFF] relative z-[22] lg:w-[41.5625vw] pt-[35vw] pb-[15vw] sm:pt-[28vw] sm:pb-[15vw] lg:pb-[8.28125vw] lg:pt-[14.4270833333vw]">
            <h1 data-aos="fade-up" className="text60">
              Privacy Policy
            </h1>
          </div>
          <div className="hidden lg:block absolute z-[1] right-0 bottom-0">
            <img
              className="h-[150px] lg:h-[23vw]"
              src="/assets/images/home/banana.png"
              alt="Privacy Policy"
            />
          </div>
        </div>
      </section>
      <section className='privacySection'>
        <div className="text22 text-[#18181B] lg:pt-[6.51041666667vw] pt1 lg:w-[62.2395833333vw] mx-[auto] sm:w-[87%] w-[87%] flex flex-col sm:gap-y-[2.4166666667vw] gap-y-[6.3846153846vw] lg:gap-y-[1.66666666667vw]">
          <div>
            <h5 className='fw-bold mb-1'>Introduction</h5>
            <p>At Grand Automation, your privacy is of great importance to us. This policy describes the types of
              information we may collect from you or that you may provide when you visit the website and our
              practices for collecting, using, maintaining, protecting, and disclosing that information.</p>
          </div>

          <div>
            <h5 className='fw-bold mb-1'>Information Collection and Use</h5>
            <p>We collect several types of information from and about users of our services, including information
              that can identify you such as name, email address, and phone number.</p>
          </div>

          <div>
            <h5 className='fw-bold mb-1'>Cookies and Tracking</h5>
            <p>We use cookies and similar tracking technologies to track the activity on our service and hold certain
              information.</p>
          </div>

          <div>
            <h5 className='fw-bold mb-1'>Data Security</h5>
            <p>We have implemented measures designed to secure your personal information from accidental loss
              and from unauthorized access, use, alteration, and disclosure.</p>
          </div>

          <div>
            <h5 className='fw-bold mb-1'>Changes to This Privacy Policy</h5>
            <p>This Privacy Policy may change from time to time. We will notify you of any changes by posting the
              new Privacy Policy on this page.</p>
          </div>

          <div>
            <h5 className='fw-bold mb-1'>Contact Us</h5>
            <p>If you have any questions about these Terms or our Privacy Policy, please contact us at
              <a href='https://support.grandautomation.io/login' target='_blank' className='ms-2 text-decoration-underline'>support.grandautomation.io.</a>
            </p>
          </div>
        </div>
      </section> <SupportSection />
      <Footer />
    </div>
  )
}

export default PrivacyPolicy
